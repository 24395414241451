import { LocalStorage, LocalStorageKeys } from '@/app'
import { PlacesList, PlacesListProps } from './PlacesList'
import { useState } from 'react'
import { onMount } from '@codeleap/common'
import { PlacePrediction } from '@/types'
import { AutocompleteUtils } from '@/utils'

export type RecentPlacesListProps = Partial<PlacesListProps>

export const RecentPlacesList = ({ onItemPress, ...rest }: RecentPlacesListProps) => {
  const myLocation = AutocompleteUtils.userLocationToPlace()
  const [recentSearches, setRecentSearches] = useState<PlacePrediction[]>([myLocation])

  onMount(() => {
    const recentLocations = LocalStorage.getItem(LocalStorageKeys.RECENT_SEARCH_PLACES as unknown as any) as unknown as PlacePrediction[]
    if (recentLocations?.length > 0) {
      setRecentSearches([myLocation, ...recentLocations])
    }
  })

  return (
    <PlacesList {...rest} label='Recents' type='Recent' noItemTopBorder places={recentSearches} onItemPress={onItemPress} />
  )
}
