import {
  ModalComposition,
  TouchableComposition,
  assignTextStyle,
} from '@codeleap/common'
import { variantProvider } from '../theme'
import { TextInputComposition } from '@codeleap/web'

export type PlacesAutocompleteComposition =
  | 'wrapper'
  | `touchable${Capitalize<TouchableComposition>}`
  | 'icon'
  | 'loaderWrapper'
  | 'inputWrapper'
  | 'resultsWrapper'
  | 'noSearchWrapper'
  | 'noSearchTitle'
  | 'placesList'
  | 'overlayWrapper'
  | 'overlayInputWrapper'
  | `overlay${Capitalize<ModalComposition>}`
  | `searchInput${Capitalize<TextInputComposition>}`

const createPlacesAutoCompleteStyle =
  variantProvider.createVariantFactory<PlacesAutocompleteComposition>()

const BREAKPOINT = 'mobile'

export const PlacesAutocompleteStyles = {
  default: createPlacesAutoCompleteStyle((theme) => ({
    wrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.relative,
    },
    loaderWrapper: {
      ...theme.spacing.marginHorizontal(2),
      ...theme.spacing.marginTop(2),
      ...theme.presets.column,
      ...theme.presets.center,
    },
    inputWrapper: {
      ...theme.presets.fullWidth,
    },
    overlayInputWrapper: {
      ...theme.presets.fullWidth,
      ...theme.spacing.marginBottom(4),
    },
    resultsWrapper: {
      ...theme.presets.absolute,
      ...theme.presets.column,
      backgroundColor: theme.colors.background,
      ...theme.presets.fullWidth,
      top: theme.values.itemHeight.default + theme.spacing.value(3),
      left: 0,
      border: `1px solid ${theme.colors.border}`,
      borderRadius: theme.borderRadius.medium,
      maxHeight: theme.values.itemHeight.default * 10,
      overflowY: 'auto',
      zIndex: 3,
    },
    noSearchWrapper: {
      ...theme.presets.column,
    },
    noSearchTitleWrapper: {
      ...theme.spacing.padding(2),

      [theme.media.down(BREAKPOINT)]: {
        ...theme.spacing.padding(0),
      },
    },
    noSearchTitle: {
      ...assignTextStyle('h4')(theme).text,
      color: theme.colors.neutral8,
      fontWeight: '600',
    },
    placesList: {
      minHeight: 'auto',
    },
    overlayInnerWrapper: {
      padding: 0,
      backgroundColor: theme.colors.neutral1,
      minHeight: '100lvh',
    },
    overlayBox: {
      ...theme.spacing.padding(2),
      maxWidth: '100vw',
      ...theme.presets.fullWidth,
      minHeight: '100svh',
      borderRadius: 0,
    },
    overlayBody: {
      maxWidth: '100vw',
      ...theme.presets.fullWidth,
    },
    recentSearchesList: {
      minHeight: 'auto',
    },
  })),
  small: createPlacesAutoCompleteStyle((theme) => ({
    searchInputWrapper: {
      maxHeight: theme.values.itemHeight.small,
    },
    resultsWrapper: {
      top: theme.values.itemHeight.small + theme.spacing.value(3),
    },
  })),
  mid: createPlacesAutoCompleteStyle((theme) => ({
    searchInputWrapper: {
      maxHeight: theme.values.itemHeight.default,
    },
    resultsWrapper: {
      top: theme.values.itemHeight.default + theme.spacing.value(3),
    },
  })),
}
