import { variantProvider } from '../theme'
import { includePresets } from '@codeleap/common'
import { assignTextStyle } from './Text'
import { LogoComposition } from './Logo'

export type FooterComposition =
  'wrapper' |
  'innerWrapper' |
  `logo${Capitalize<LogoComposition>}` |
  'separator' |
  'content' |
  'topContent' |
  'bottomContent' |
  'contactWrapper' |
  'contactText' |
  'disclaimersWrapper' |
  'disclaimerText' |
  'socialMediaWrapper' |
  'navigateWrapper' |
  'navigateColumn' |
  'navigateTitle' |
  'navigateLink'

const createFooterStyle = variantProvider.createVariantFactory<FooterComposition>()

const presets = includePresets((s) => createFooterStyle(() => ({ wrapper: s })))

const minHeight = 420
const logoSize = 128
const BREAKPOINT = 'tabletSmall'

export const AppFooterStyles = {
  ...presets,
  default: createFooterStyle((theme) => ({
    wrapper: {
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      backgroundColor: theme.colors.primary1,
      minHeight: minHeight,
      ...theme.presets.column,
      ...theme.presets.fullWidth,
      ...theme.spacing.paddingVertical(4),

      [theme.media.down(BREAKPOINT)]: {
        ...theme.spacing.paddingVertical(2),
      },
    },
    innerWrapper: {
      ...theme.presets.column,
    },
    logoImage: {
      width: logoSize,
      marginBottom: theme.spacing.value(5),
      alignSelf: 'flex-start',
      marginRight: 'auto',

      [theme.media.down(BREAKPOINT)]: {
        alignSelf: 'center',
      },
    },
    separator: {
      ...theme.presets.fullWidth,
      backgroundColor: theme.colors.primary4,
      height: 2,
    },
    content: {
      ...theme.presets.column,
      ...theme.presets.flex,
      gap: theme.spacing.value(5),
    },
    topContent: {
      ...theme.presets.row,
      ...theme.presets.flex,
      ...theme.presets.justifySpaceBetween,
      gap: theme.spacing.value(5),

      [theme.media.down(BREAKPOINT)]: {
        ...theme.presets.column,
      },
    },
    bottomContent: {
      ...theme.presets.row,
      ...theme.presets.justifySpaceBetween,
      gap: theme.spacing.value(5),

      [theme.media.down(BREAKPOINT)]: {
        ...theme.presets.column,
      },
    },
    disclaimersWrapper: {
      flex: 3,
      display: 'flex',
      flexDirection: 'column',
    },
    disclaimerText: {
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.neutral9,
      display: 'inline-block',

      [theme.media.down(BREAKPOINT)]: {
        alignSelf: 'center',
        ...theme.presets.textCenter,
      },
    },
    navigateWrapper: {
      gap: theme.spacing.value(5),

      [theme.media.down(BREAKPOINT)]: {
        ...theme.presets.column,
        gap: theme.spacing.value(3),
      },
    },
    navigateColumn: {
      ...theme.presets.column,
      gap: theme.spacing.value(2),

      [theme.media.down(BREAKPOINT)]: {
        ...theme.presets.alignCenter,
      },
    },
    navigateTitle: {
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.neutral9,
    },
    navigateLink: {
      ...assignTextStyle('h5')(theme).text,
      color: theme.colors.neutral10,
      textDecoration: 'none',
      fontWeight: '600',
    },
    contactWrapper: {
      ...theme.presets.column,
      ...theme.presets.fullHeight,
      ...theme.presets.justifySpaceBetween,
      ...theme.presets.alignEnd,
      gap: theme.spacing.value(1),

      [theme.media.down(BREAKPOINT)]: {
        ...theme.presets.alignCenter,
      },
    },
    contactText: {
      ...assignTextStyle('p1')(theme).text,
      color: theme.colors.neutral10,
      fontWeight: '300',
      textDecoration: 'none',
    },
    socialMediaWrapper: {
      gap: theme.spacing.value(2),

      [theme.media.down(BREAKPOINT)]: {
        ...theme.presets.fullWidth,
        ...theme.presets.center,
      },
    },
  })),
}
