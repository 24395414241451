/* eslint-disable max-lines */
import { variantProvider } from '../theme'
import { ButtonComposition, ButtonPresets } from '@codeleap/web'
import { assignTextStyle } from '..'

const createButtonStyle =
  variantProvider.createVariantFactory<ButtonComposition>()

export const AppButtonStyles = {
  ...ButtonPresets,
  default: createButtonStyle((theme) => ({
    wrapper: {
      cursor: 'pointer',
      border: 'none',
      outline: 'none',
      ...theme.presets.row,
      ...theme.presets.relative,
      backgroundColor: theme.colors.primary3,
      borderRadius: theme.borderRadius.smallish,
      transitionProperty: 'background, color, border, filter',
      transitionDuration: '0.2s',
      ...theme.presets.alignCenter,
      ...theme.presets.justifyCenter,
      width: 'auto',

      '&:hover': {
        backgroundColor: theme.colors.primary4,
      },
      ...theme.spacing.padding(1),
      ...theme.spacing.paddingHorizontal(2),
    },
    text: {
      textAlign: 'center',
      color: theme.colors.neutral1,
    },
    'text:disabled': {
      color: theme.colors.neutral5,
    },
    loaderWrapper: {
      height: theme.values.iconSize[4],
      width: theme.values.iconSize[4],
    },
    icon: {
      color: theme.colors.neutral1,
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    leftIcon: {
      ...theme.spacing.marginRight('auto'),
    },
    rightIcon: {
      ...theme.spacing.marginLeft('auto'),
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral2,

      cursor: 'not-allowed',

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
    },
  })),
  large: createButtonStyle((theme) => ({
    wrapper: {
      height: theme.values.itemHeight.default,
    },
    text: {
      fontWeight: '500',
    },
    loaderWrapper: {
      width: theme.values.iconSize[5],
      height: theme.values.iconSize[5],
    },
    icon: {
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
    },
    leftIcon: {
      ...theme.spacing.marginRight(1),
    },
    rightIcon: {
      ...theme.spacing.marginLeft(1),
    },
  })),
  small: createButtonStyle((theme) => ({
    wrapper: {
      height: theme.values.itemHeight.small,
      ...theme.spacing.paddingHorizontal(1),
    },
    text: {
      fontSize: theme.typography.base.styles.p3.size,
      fontWeight: '500',
    },
    loaderWrapper: {
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
    icon: {
      width: theme.values.iconSize[1],
      height: theme.values.iconSize[1],
    },
    leftIcon: {
      ...theme.spacing.marginLeft(1),
    },
    rightIcon: {
      ...theme.spacing.marginRight(1),
    },
  })),
  outline: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.neutral5(theme.values.borderWidth.small),

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
        ...theme.border.primary2(theme.values.borderWidth.small),
      },
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.neutral2(theme.values.borderWidth.small),

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
    },
    text: {
      color: theme.colors.neutral10,
    },
    'text:disabled': {
      color: theme.colors.neutral5,
    },
    icon: {
      color: theme.colors.neutral10,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    loaderWrapper: {
      color: theme.colors.neutral10,
    },
    'loaderWrapper:disabled': {
      color: theme.colors.neutral5,
    },
  })),
  'primary:outline': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.primary3(theme.values.borderWidth.small),

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
        ...theme.border.primary4(theme.values.borderWidth.small),
      },
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral2,
      ...theme.border.neutral2(theme.values.borderWidth.small),

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },
    },
    text: {
      color: theme.colors.primary3,
    },
    icon: {
      color: theme.colors.primary3,
    },
    loaderWrapper: {
      color: theme.colors.primary3,
    },
  })),
  minimal: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral1,

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
    },
    text: {
      color: theme.colors.primary3,
    },
    'text:disabled': {
      color: theme.colors.neutral5,
    },
    icon: {
      color: theme.colors.primary3,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    loaderWrapper: {
      color: theme.colors.primary3,
    },
    'loaderWrapper:disabled': {
      color: theme.colors.neutral5,
    },
  })),
  destructive: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.destructive2,

      '&:hover': {
        backgroundColor: theme.colors.destructive2,
        filter: 'brightness(90%)',
      },
    },
    text: {
      color: theme.colors.neutral1,
    },
    icon: {
      color: theme.colors.neutral1,
    },
    loaderWrapper: {
      color: theme.colors.neutral1,
    },
  })),
  'destructive:outline': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.neutral5(theme.values.borderWidth.small),

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
        ...theme.border.neutral5(theme.values.borderWidth.small),
      },
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.neutral2(theme.values.borderWidth.small),
    },
    text: {
      color: theme.colors.destructive2,
    },
    icon: {
      color: theme.colors.destructive2,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    loaderWrapper: {
      backgroundColor: theme.colors.destructive2,
    },
  })),
  'destructive:minimal': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
    },
    'wrapper:disabled': {
      backgroundColor: theme.colors.neutral1,
    },
    text: {
      color: theme.colors.destructive2,
    },
    icon: {
      color: theme.colors.destructive2,
    },
    'icon:disabled': {
      color: theme.colors.neutral5,
    },
    loaderWrapper: {
      color: theme.colors.destructive2,
    },
  })),
  link: createButtonStyle((theme) => ({
    wrapper: {
      textDecoration: 'none',
      color: theme.colors.neutral10,
    },
    text: {
      textDecoration: 'none',
      color: theme.colors.neutral10,
    },
  })),
  primary6: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.primary6,

      '&:hover': {
        backgroundColor: theme.colors.primary1,
      },
    },
    text: {
      color: theme.colors.primary3,
    },
  })),
  primary1: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.primary1,

      '&:hover': {
        backgroundColor: theme.colors.primary1,
        opacity: 0.6,
      },
    },
    text: {
      color: theme.colors.primary3,
    },
  })),
  'link:outline': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
      ...theme.border.primary3(theme.values.borderWidth.small),
      color: theme.colors.primary3,

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
        ...theme.border.primary4(theme.values.borderWidth.small),
      },
    },
    text: {
      color: theme.colors.primary3,
    },
  })),
  noPadding: createButtonStyle((theme) => ({
    wrapper: {
      ...theme.spacing.padding(0),
    },
  })),
  pill: createButtonStyle((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.medium,
    },
  })),
  centerLeftIcon: createButtonStyle((theme) => ({
    wrapper: {
      ...theme.presets.center,
    },
    leftIcon: {
      marginRight: 0,
    },
    rightIcon: {
      marginLeft: 0,
      display: 'none',
    },
    text: {
      marginLeft: theme.spacing.value(1),
    },
  })),
  centerRightIcon: createButtonStyle((theme) => ({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center',
      alignSelf: 'center',
    },
    rightIcon: {
      marginLeft: 0,
    },
    text: {
      ...theme.spacing.marginRight(1),
    },
    leftIcon: {
      display: 'none',
    },
  })),
  downloadAppButton: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.primary2,
      borderRadius: theme.borderRadius.smallish,
      maxWidth: 560,
      '&:hover': {
        backgroundColor: theme.colors.primary2,
      },
      [theme.media.down('tabletSmall')]: {
        minWidth: '100%',
        ...theme.spacing.marginTop(2),
      },
    },
    icon: {
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
    text: {
      color: theme.colors.primary3,
    },
  })),
  card: createButtonStyle((theme) => ({
    wrapper: {
      ...theme.presets.elevated,
      backgroundColor: theme.colors.neutral1,
      paddingLeft: theme.spacing.value(1),
      paddingRight: theme.spacing.value(1),
      paddingTop: theme.spacing.value(1.5),
      paddingBottom: theme.spacing.value(1.5),

      '&:hover': {
        backgroundColor: theme.colors.neutral3,
      },
    },
    text: {
      ...assignTextStyle('h5')(theme).text,
      color: theme.colors.primary3,
    },
    icon: {
      color: theme.colors.primary3,
    },
    leftIcon: {
      marginRight: theme.spacing.value(1),
    },
  })),
  downloadSectionAppButton: createButtonStyle((theme) => {
    const DOWNLOAD_SECTION_BTN_WIDTH = 320

    return {
      wrapper: {
        backgroundColor: theme.colors.primary3,
        maxWidth: DOWNLOAD_SECTION_BTN_WIDTH,
        '&:hover': {
          backgroundColor: theme.colors.primary4,
        },
      },
      text: {
        color: theme.colors.neutral1,
      },
    }
  }),
  fitlerButton: createButtonStyle((theme) => {
    return {
      wrapper: {
        backgroundColor: theme.colors.neutral2,
        borderRadius: theme.borderRadius.medium,
        '&:hover': {
          backgroundColor: theme.colors.neutral3,
        },
        ...theme.spacing.paddingHorizontal(1.5),
      },
      text: {
        ...assignTextStyle('p3')(theme).text,
        color: theme.colors.neutral9,
      },
      leftIcon: {
        fill: theme.colors.primary3,
        marginRight: theme.spacing.value(1),
        height: theme.spacing.value(1),
        width: theme.spacing.value(1),
      },
      rightIcon: {
        color: theme.colors.neutral9,
        marginLeft: theme.spacing.value(0.5),
      },
    }
  }),
  'border-radius-smallish': createButtonStyle((theme) => {
    return {
      wrapper: {
        borderRadius: theme.borderRadius.smallish,
      },
    }
  }),
  'fitlerButton:selected': createButtonStyle((theme) => {
    return {
      wrapper: {
        backgroundColor: theme.colors.primary3,
        borderRadius: theme.borderRadius.medium,
        '&:hover': {
          backgroundColor: theme.colors.primary3,
        },
        ...theme.spacing.paddingHorizontal(1.5),
      },
      text: {
        ...assignTextStyle('p3')(theme).text,
        color: theme.colors.neutral1,
      },
      leftIcon: {
        fill: theme.colors.neutral1,
        marginRight: theme.spacing.value(1),
        height: theme.spacing.value(1),
        width: theme.spacing.value(1),
      },
      rightIcon: {
        color: theme.colors.neutral1,
        marginLeft: theme.spacing.value(0.5),
      },
    }
  }),

  testimonalSectionAppButton: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.transparent,
      '&:hover': {
        backgroundColor: theme.colors.transparent,
      },

      [theme.media.down('tabletSmall')]: {
        minWidth: '100%',
        ...theme.spacing.marginTop(0),
      },
    },
    text: {
      color: theme.colors.primary3,
    },
  })),
  'filterAndIcon:selected': createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.primary6,
    },
    icon: {
      color: theme.colors.primary3,
    },
    text: {
      color: theme.colors.primary3,
    },
  })),
  filterRating: createButtonStyle((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.medium,
    },
    icon: {
      color: theme.colors.primary3,
    },
    text: {
      fontSize: theme.typography.base.styles.p3.size,
      lineHeight: '17px',
    },
    rightIcon: {
      ...theme.spacing.marginLeft(0.5),
    },
  })),
  secondary: createButtonStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.secondary,

      '&:hover': {
        backgroundColor: theme.colors.primary2,
      },
    },
    text: {
      color: theme.colors.primary3,
    },
    rightIcon: {
      color: theme.colors.primary3,
    },
    icon: {
      color: theme.colors.primary3,
    },
    leftIcon: {
      marginRight: theme.spacing.value(1),
    },
  })),
}
