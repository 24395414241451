import { Course, CourseFilters } from '@/types'
import { navigate } from './index'
import { IsDevEnv } from '@/app'
import { navigate as navigateGatsby } from 'gatsby'

export const navigateToExplore = (
  filters?: CourseFilters & { search?: string },
) => {
  navigate('Explore', {
    params: filters as any,
  })
}

export const navigateToDetails = (
  course: Pick<Course, 'slug' | 'location_name_slug' | 'id' | 'nearest_area'>,
) => {
  const slug = course?.slug
  const locationSlug = course?.location_name_slug
  const nearestArea = course?.nearest_area

  if (!slug || (!locationSlug && !nearestArea?.slug) || IsDevEnv) {
    navigate(`Run`, {
      route: `${course?.id}`,
    })
    return
  }

  const coursePath = `/${nearestArea?.slug || locationSlug}/${slug}`

  navigateGatsby(coursePath)
}
