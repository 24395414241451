import { ActivityIndicator, CourseCard, CourseCardProps, List, Scroll, View } from '@/components'
import { Course } from '@/types'
import { IconPlaceholder, PropsOf } from '@codeleap/common'
import { useCallback } from 'react'
import { onScrollToBottom } from '@/utils'
import { EmptyPlaceholder } from '@codeleap/web'
import { Theme } from '@/app'

export type CourseListProps = Partial<PropsOf<typeof List>> & {
  id?: string
  courses: Course[]
  cardVariants?: CourseCardProps['variants']
  onPress?: (course: Course) => void
  onHover?: (course: Course) => void
  onMouseLeave?: (course: Course) => void
  as?: 'scroll' | 'list'
}

export const CourseList = ({
  as = 'list',
  onMouseLeave,
  onHover,
  ListHeaderComponent,
  variants = [],
  courses,
  cardVariants = ['wide'],
  onPress,
  ...rest }: CourseListProps) => {
  const isList = as === 'list'

  const renderItem = useCallback(({ item }) => {
    return (
      <CourseCard
        course={item}
        key={item.id}
        onHover={() => { onHover?.(item) }}
        onMouseLeave={() => onMouseLeave?.(item)}
        onPress={() => { onPress?.(item) }}
        variants={[...cardVariants, isList && 'fullWidth' as any]}
      />
    )
  }, [cardVariants, onPress, onHover, onMouseLeave, isList])

  if (rest.isLoading) {
    return (
      <View variants={['justifyCenter', 'flex', 'alignCenter']} style={rest.style}>
        <ActivityIndicator debugName='CourseList:Loading' />
      </View>)
  }

  if (!courses?.length) {
    return (
      <EmptyPlaceholder
        icon={'map-search' as IconPlaceholder}
        title='No courses'
        description='There are no courses on this location'
        style={rest.style}
        variants={['compact', 'icon:size4'] as any}
      />
    )
  }

  if (isList) {
    return (
      <List
        debugName='CourseList'
        data={courses as unknown as string[]}
        rowItemsSpacing={Theme.spacing.value(3)}
        renderItem={renderItem}
        layoutWrapperProps={{
          component: 'ol'
        }}
        {...rest} />
    )
  }

  return (
    <Scroll
      id={rest.id}
      style={rest.style}
      variants={[
        'column',
        'fullWidth',
        'paddingBottom:4',
        'fullHeight',
        ...variants as any[]]
      }
      onScroll={e => {
        if (!rest.hasNextPage) return
        onScrollToBottom(e, rest.fetchNextPage)
      }}>
      {ListHeaderComponent?.()}

      <View variants={['column', 'paddingHorizontal:2', 'gap:3']} component='ol'>
        {courses.map((course) => renderItem({ item: course }))}
      </View>

      {rest.isFetchingNextPage && (
        <View variants={['justifyCenter']}>
          <ActivityIndicator debugName='CourseList:Loading' />
        </View>)
      }
    </Scroll>
  )
}

