import {
  Page,
  Text,
  CenterWrapper,
  Section,
  View,
  AreaMap,
  DynamicPageHandler,
  CourseGrid,
  Image,
} from '@/components'
import { variantProvider } from '@/app'
import { Navigation, useFlatlistProps, useIsMobile, AreaUtils, useMaxContentWidth } from '@/utils'
import { APIClient } from '@/services'
import { Area } from '@/types'
import { useBreakpointMatch } from '@codeleap/web'
import { AnyFile } from '@codeleap/common/dist/tools/Form/types'
import { onMount } from '@codeleap/common'
import { AppStatus } from '@/redux'

type ImageSectionProps = {
  coverImage: AnyFile | null
  coverTitle: string
  isMobile: boolean
  width: number
  area: Area
}

type CoursesSectionsProps = {
  isMobile: boolean
  isLoading: boolean
  courses: ReturnType<typeof APIClient.Courses.useCourses>['courses']
  onPressViewAll?: () => void
  listProps: ReturnType<typeof useFlatlistProps>
}

const ImageSection = (props: ImageSectionProps) => {

  const { coverImage, coverTitle, isMobile, width, area } = props

  const Wrapper = isMobile ? View : CenterWrapper

  return (
    <Wrapper
      variants={['marginTop:auto', 'marginBottom:4'] as any}
      innerProps={{ variants: ['marginTop:auto'] }}
    >
      <View
        style={{
          height: `calc(${width}px - ${width / 2}px)`,
        }}
        css={[styles.imageSectionWrapper, styles.bottomRadius]}
      >
        <Image source={coverImage} css={[styles.image, styles.bottomRadius]} alt={AreaUtils.getImageAlt(area)} />

        <View css={[styles.imageContent, styles.bottomRadius]}>
          <Text component='h1' variants={['h0', 'color:neutral1', 'marginBottom:3', 'marginTop:auto']} text={coverTitle} />
        </View>
      </View>
    </Wrapper>
  )
}

const CoursesSections = (props: CoursesSectionsProps) => {

  const { courses, onPressViewAll, listProps } = props

  const numColumns = useBreakpointMatch({
    mobile: 1,
    tabletSmall: 2,
    laptop: 3,
    desktopHuge: 4,
  })

  return (
    <Section
      title={'Trails'}
      variants={['column', 'marginTop:2', 'marginBottom:4', 'title:h2'] as any}
      centerContent={false}
      isLoading={courses.list.query.isLoading}
      showButton
      buttonProps={{
        debugName: 'Trail section button',
        variants: ['minimal', 'small', 'padding:0'],
        onPress: onPressViewAll,
        text: 'View all',
      }}

    >
      <CourseGrid
        courses={courses?.items}
        numColumns={numColumns}
        {...listProps}
        debugName='Trail section grid'
      />
    </Section>
  )
}

export type AreaPageProps = {
  area: Area
}

export const AreaPage = ({ area }: AreaPageProps) => {

  const { courses } = APIClient.Courses.useCourses({
    enabled: !!area,
    lat: area?.latitude,
    lng: area?.longitude,
    radius: area?.radius,
    limit: 12,
  })

  const coverTitle = `Best runs in ${area?.title}`

  onMount(() => {
    AppStatus.set('idle')
  })

  const isMobile = useIsMobile()

  const listProps = useFlatlistProps(courses, {
    noMoreItemsText: '',
  })

  const coverImage = AreaUtils.getCoverImage(area)

  const { width } = useMaxContentWidth()

  return (
    <DynamicPageHandler item={area} isSSR>
      <Page
        centerContent={false}
        SEOProps={AreaUtils.getSEOProps(area)}
      >
        <ImageSection
          area={area}
          coverImage={coverImage}
          coverTitle={coverTitle}
          isMobile={isMobile}
          width={width}
        />
        <CenterWrapper innerProps={{ variants: ['column'] }}>
          <View
            css={styles.sectionWrapper}
            variants={['gap:4', 'marginBottom:10']}
            responsiveVariants={{
              tabletSmall: ['marginBottom:4'],
              mobile: ['marginBottom:4'],
            }}
          >
            <View variants={['column', 'gap:2']}>
              {!isMobile ? <Text text={'Description'} /> : null}
              <Text
                text={`${area?.description}`}
              />
            </View>
            <AreaMap
              area={area}
              courses={courses?.items}
            />
          </View>
          <CoursesSections
            courses={courses}
            isMobile={isMobile}
            isLoading={courses.list.query.isLoading}
            onPressViewAll={() => Navigation.Course.navigateToExplore({
              lat: area?.latitude,
              lng: area?.longitude,
              radius: area?.radius,
            })}
            listProps={listProps}
          />

        </CenterWrapper>
      </Page>
    </DynamicPageHandler>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    imageSectionWrapper: {
      minHeight: 400,
      ...theme.presets.relative,
      ...theme.presets.fullWidth,
    },
    bottomRadius: {
      [theme.media.up('mobile')]: {
        borderBottomRightRadius: theme.borderRadius.medium,
        borderBottomLeftRadius: theme.borderRadius.medium,
      },
    },
    imageContent: {
      ...theme.presets.relative,
      ...theme.presets.fullWidth,
      ...theme.presets.fullHeight,
      ...theme.spacing.paddingHorizontal(2),
      backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)`,
      zIndex: 1,
    },
    image: {
      objectFit: 'cover',
      ...theme.presets.absolute,
      ...theme.presets.fullWidth,
      ...theme.presets.fullHeight,
      zIndex: 0,
    },
    map: {
      borderRadius: theme.borderRadius.medium,
      overflow: 'hidden',
      width: '100%',
      height: 300,
    },
    sectionWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      [theme.media.down('mobile')]: {
        gridTemplateColumns: '1fr',
      },
    },
  }),
  true,
)

