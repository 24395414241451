export const Testimonials = [
  {
    testimonial: '“Great app, love the graded leader board so anyone and everyone can be as competitive as they like. Did a whole series of runs as part of an event and had a great time exploring new routes, whilst being navigated and prompted by the app throughout.”',
    source: 'play-store',
  },
  {
    testimonial: '“Great app for discovering off road running routes according to your location. The grading based on age and the leaderboards enable you to test yourself against peers, and your own progression. Winner!”',
    source: 'apple-store',
  },
  {
    testimonial: "“If you're somewhere new and need to find a really good running route use this app! It’s easy to find a route; the details show you how close you are to a route and gives details of what to expect (distance/how hilly/terrain) and off you go. And once you’ve done it once, you can try it again and compare. Brilliant!”",
    source: 'apple-store',
  },
  {
    testimonial: "“Superb! I can't believe how many routes are on here. Great for finding routes when away for a weekend or for just monitoring your progress on a local route. Very easy to use and really good navigation tool.”",
    source: 'play-store',
  },
  {
    testimonial: '“Love it!  Got this app a few months ago and have been using it ever since. It continually surprises me on showing new routes and linking old routes with new in amazing combinations that I had not thought of.”',
    source: 'apple-store',
  },
]
