import { createSettings } from '@codeleap/common'
import Obfuscate from './Obfuscate'
import * as Sentry from '@sentry/gatsby'

export const IsDevEnv = process.env.NODE_ENV === 'development'

const enableSentry = !IsDevEnv

const ENV = IsDevEnv ? 'development' : 'production'

const _Settings = createSettings({
  AppName: 'Skamper',
  CompanyName: 'Skamper',
  Description: 'Running App',
  AppConfig: {
    emailVerificationEnabled: true,
    signupCompleteVerificationEnabled: true,
  },
  Environment: {
    IsDev: IsDevEnv,
    Type: ENV,
    InitTime: new Date(),
  },
  Images: {
    Aspect: {
      default: 1,
    },
  },
  Application: {
    IsBrowser: false,
  },
  PerformanceInspector: {
    enable: true,
    maxRenders: 30,
  },
  Website: {
    ProductionURL: 'http://template.codeleap.co.uk/',
    DevelopmentURL: 'http://template.codeleap.co.uk/',
  },
  Stores: {
    Apple: {
      url: 'https://apps.apple.com/us/app/skamper/id6446036829',
    },
    Android: {
      url: 'https://play.google.com/store/search?q=skamper&c=apps',
    },
  },
  Fetch: {
    Thunderforest: 'https://tile.thunderforest.com/outdoors/',
    IPURL: 'https://ipinfo.io/json',
    ProductionURL: process.env.GATSBY_API_URL,
    DevelopmentURL: 'https://api-skamper.codeleap.co.uk/',
    ViewHTMLErrors: true,
    GeocodingURL: 'https://maps.googleapis.com/maps/api/geocode/json?',
  },
  Social: {
    FacebookURL: 'https://facebook.com/skamperapp/',
    LinkedinURL: 'https://www.linkedin.com/company/codeleap-uk',
    InstagramURL: 'https://instagram.com/skamperapp',
  },
  ContactINFO: {
    Website: 'codeleap.co.uk',
    TermsAndPrivacy: 'https://skamper.app/terms-conditions/',
    SupportEMAIL: 'support@skamper.co.uk',
    ContactEMAIL: 'info@skamper.com',
    ContactPHONE: '+44 (0) 333 050 9420',
  },
  Logger: {
    Level: 'debug',
    // TODO - get device or browser id
    DeviceIdentifier: '',
    StringifyObjects: true,
    IgnoreWarnings: [
      `[react-native-gesture-handler] Seems like you're using`,
      `Require cycle:`,
      `Require cycles are allowed`,
      `Running `,
      `WARN  Require cycle`,
      ` Warning: Failed`,
      `Warning: Failed`,
      'new NativeEventEmitter',
      'User cancelled',
      'React does not recognize the',
      'Unknown event handler property',
      'forwardRef render functions accept exactly ',

    ],
    Obfuscate,
    ComponentLogging: true,
  },
  Sentry: {
    enable: enableSentry,
    provider: Sentry,
    initArgs: {
      enabled: enableSentry,
      environment: ENV,
    },
    dsn: 'https://6a56cb8177701d9b054c208cde1dd69c@o309198.ingest.sentry.io/4506752100073472',
  },
  Slack: {
    echo: {
      icon: 'https://avatars.githubusercontent.com/u/48894125?s=200&v=4',
      token: 'xoxb-622265672359-1248324007429-Ts31vPT8jCNh7L99xtdbOgQB',
      channel: '#_dev_logs',
    },
  },
  ApiCredentials: {
    GoogleSignin: {
      WebClientId:
        '268760770384-ob7jfjntuorgqaj2tt2mvnqdokd634et.apps.googleusercontent.com',
    },
    AppleSignIn: {
      ServiceClientId: '',
      RedirectURI: '',
    },
    FacebookSDK: {
      AppId: '1130448934268035',
    },
    Thunderforest: {
      name: 'thunderforest_outdoors',
      ApiKey: 'cd61bd9f68b349e5b392960715fb96ad',
    },
    GoogleMaps: {
      MapId: 'c81834b798176ba1',
      ApiKey: 'AIzaSyALj43vzUI8ouIBYXwWG1alC7t-TbjSWPM',
    },
    GooglePlacesAutoComplete: {
      Url: 'https://maps.googleapis.com/maps/api',
    },
  },
})

if (_Settings.Environment.IsDev) {
  // TODO read environment
  // _Settings = deepMerge(_Settings, env)
}

if (!_Settings.ContactINFO.TermsAndPrivacy) {
  _Settings.ContactINFO.TermsAndPrivacy = `https://codeleap.co.uk/dev/policies/?app=${Buffer.from(_Settings.AppName, 'base64')}`
}

export const Settings = _Settings
