import { PropsOf, useCallback } from '@codeleap/common'
import { Text, View } from '..'
import { Course } from '@/types'

type CourseTagsProps = {
  course: Course
  wrapperProps?: PropsOf<typeof View>
  textProps?: PropsOf<typeof Text>
}

export const CourseTags = (props: CourseTagsProps) => {

  const { course, wrapperProps = {}, textProps = {} } = props

  const wrapperVariants = wrapperProps?.hasOwnProperty('variants') ? wrapperProps?.variants : []
  const textVariants = textProps?.hasOwnProperty('variants') ? textProps?.variants : []

  const renderItem = useCallback((item, index) => {
    return (
      <View
        {...wrapperProps}
        key={`${item?.id}${index}`}
        variants={['bg:neutral2', 'padding:1', 'border-radius:rounded', ...wrapperVariants]}
        component='li'
      >
        <Text
          {...textProps}
          variants={['p3', 'regular', ...textVariants]}
          text={item?.title}
        />
      </View>
    )
  }, [])

  if (!course?.tags?.length) return null

  return course?.tags?.map?.((item, index) => renderItem(item, index))
}
