import React, { useRef, useState } from 'react'
import { Location, PlacePrediction } from '@/types'
import { MapDefaults, MyLocation, Settings } from '@/app'
import { SearchBarProps, View, Scroll, SearchBar } from '@/components'
import { PlacesAutocompleteStyles } from '@/app/stylesheets'
import { AutocompleteUtils, toShortCoords, useClickClosest, useIsMobile } from '@/utils'
import { PlacesAutoCompleteModal, PlacesResults, PlacesResultsProps } from './components'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { ComponentVariants, StylesOf, getNestedStylesByKey, onMount, useBooleanToggle, useDefaultComponentStyle } from '@codeleap/common'
import { PlacesAutocompleteComposition } from '@/app/stylesheets/PlacesAutocomplete'

const ELEMENT_IDS = {
  searchInput: 'places-autocomplete-search-input',
  results: 'places-autocomplete-results',
  clearIcon: 'places-autocomplete-clear-icon',
}
const SEARCH_INPUT_DEBOUNCE = 1000

export type PlacesAutoCompleteProps = {
  searchText: string
  defautlValue?: string
  onChangeSearch: SearchBarProps['onSearchChange']
  onClear?: SearchBarProps['onClear']
  styles?: StylesOf<PlacesAutocompleteComposition>
  showResults?: boolean
  typesOptions?: string[]
  placeholder?: SearchBarProps['placeholder']
  noEmptyPlaceholder?: PlacesResultsProps['noEmptyPlaceholder']
  searchBarProps?: Partial<SearchBarProps>
  onItemPress?: PlacesResultsProps['onItemPress']
  origin?: Location
  value?: string
  setValue?: (value: string) => void
} & ComponentVariants<typeof PlacesAutocompleteStyles>

const _PlacesAutoComplete = ({
  variants,
  responsiveVariants,
  searchText,
  defautlValue,
  onChangeSearch,
  typesOptions = ['locality'],
  onItemPress,
  onClear,
  placeholder = 'Search locations',
  searchBarProps,
  styles,
  origin,
  value,
  noEmptyPlaceholder,
  setValue,
  ...rest
}: PlacesAutoCompleteProps) => {
  const textInputRef = useRef(null)
  const [inputInternalValue, setInternalInputValue] = useState(defautlValue)
  const [isFocused, toggleFocused] = useBooleanToggle(false)
  const isMobile = useIsMobile()

  const inputValue = value !== null ? value : inputInternalValue
  const setInputValue = setValue || setInternalInputValue

  const variantStyles = useDefaultComponentStyle<
    'u:PlacesAutoComplete',
    typeof PlacesAutocompleteStyles
  >('u:PlacesAutoComplete', {
    variants,
    styles,
    responsiveVariants,
  })

  const getStyles = (key) => ({
    ...variantStyles[key],
    ...getNestedStylesByKey(key, variantStyles),
  })

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: Settings.ApiCredentials.GoogleMaps.ApiKey,
    options: {
      input: '',
      types: typesOptions,
      origin: toShortCoords(origin || MapDefaults.center),
    },
  })

  onMount(() => {
    if (!!defautlValue) getPlacePredictions({ input: defautlValue })
  })

  const handleItemPress = (place: PlacePrediction) => {
    const placeTitle = AutocompleteUtils.getPlaceTitle(place)
    setInputValue(placeTitle)
    getPlacePredictions({ input: placeTitle })

    if (textInputRef.current) {
      const inputRef = textInputRef.current?.getInputRef()
      inputRef.value = placeTitle
    }

    if (!!place?.location) {
      onItemPress?.({ ...place, location: place.location })
      return
    }

    placesService?.getDetails({ placeId: place?.place_id },
      (placeDetails) => {
        const location = AutocompleteUtils.placeLocation(placeDetails)
        AutocompleteUtils.saveSearch({ ...place, location })
        onItemPress?.({ ...place, location })
      },
    )
  }

  const handleSearch = async (inputValue: string) => {
    onChangeSearch(inputValue)
    getPlacePredictions({ input: inputValue })
  }

  useClickClosest({
    elementIds: [ELEMENT_IDS.searchInput, ELEMENT_IDS.searchInput, ELEMENT_IDS.clearIcon],
    onNotClickClose: () => toggleFocused(false),
    enabled: !isMobile,
  })

  const placesResultsProps: PlacesResultsProps = {
    searchText,
    isLoading: isPlacePredictionsLoading,
    places: placePredictions,
    onItemPress: handleItemPress,
    noEmptyPlaceholder: inputValue === MyLocation.name,
  }

  const searchBarDefaultProps: SearchBarProps = {
    value: inputValue,
    defaultValue: inputValue,
    onClear,
    onValueChange: setInputValue,
    onSearchChange: handleSearch,
    debugName: 'PlacesAutoComplete:SearchInput',
    debounce: SEARCH_INPUT_DEBOUNCE,
    placeholder: placeholder,
    variants: ['searchPlaces', 'noError'],
    wrapperProps: { id: ELEMENT_IDS.searchInput, component: 'form' },
    styles: getStyles('searchInput'),
  }

  return (
    <View css={getStyles('wrapper')} {...rest}>
      <SearchBar
        ref={textInputRef}
        onFocus={() => toggleFocused(true)}
        clearIconId={ELEMENT_IDS.clearIcon}
        {...searchBarDefaultProps}
        {...searchBarProps}
      />

      {isFocused && !isMobile &&
        <View css={variantStyles.resultsWrapper} id='SearchInputLocationResults'>
          <Scroll>
            <PlacesResults {...placesResultsProps} />
          </Scroll>
        </View>
      }

      {isMobile && (
        <PlacesAutoCompleteModal
          {...searchBarDefaultProps}
          placesResultsProps={placesResultsProps}
          visible={isFocused}
          toggle={toggleFocused}
          getStyles={getStyles}
        />)}
    </View>
  )
}

export const PlacesAutoComplete = (props: PlacesAutoCompleteProps) => {
  if (!props?.origin) return null
  return <_PlacesAutoComplete {...props} />
}
