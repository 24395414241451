import { variantProvider } from '../theme'
import { assignTextStyle } from './Text'

export type CourseStatsComposition =
  | 'wrapper'
  | 'itemWrapper'
  | 'itemIcon'
  | 'itemText'
  | 'itemLabel'
  | `itemIcon`

const createCourseCardStyle =
  variantProvider.createVariantFactory<CourseStatsComposition>()

export const CourseStatsStyles = {
  default: createCourseCardStyle((theme) => {
    const color = theme.colors.primary3

    return {
      wrapper: {
        ...theme.presets.justifySpaceBetween,
        gap: theme.spacing.value(2),
      },
      itemWrapper: {
        flex: 1,
        ...theme.presets.column,
        ...theme.presets.alignCenter,
        gap: theme.spacing.value(1),
      },
      itemIcon: {
        color,
        ...theme.sized(3),
      },
      itemText: {
        ...assignTextStyle('h5')(theme).text,
        whiteSpace: 'nowrap',
        color,
      },
      itemLabel: {
        color: theme.colors.neutral7,
      },
    }
  }),
}
