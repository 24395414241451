import { TextInput } from '@/components'
import React, { forwardRef, ReactElement, useImperativeHandle, useRef } from 'react'
import { AnyFunction, IconPlaceholder, PropsOf, TypeGuards } from '@codeleap/common'

export type SearchBarProps = {
  placeholder: string
  clearable?: boolean
  debugName: string
  clearIcon?: IconPlaceholder
  searchIcon?: IconPlaceholder
  debounce?: number
  onSearchChange: (search: string) => void
  onTypingChange?: (isTyping: boolean) => void
  onValueChange?: (search: string) => void
  onClear?: AnyFunction
  defaultValue?: string
  value?: string
  clearIconId?: string
} & Partial<PropsOf<typeof TextInput>>

export type InputRef = HTMLInputElement & {
  isTextInput?: boolean
  focus: () => void
  getInputRef: () => HTMLInputElement
}

export const SearchBar = forwardRef<InputRef, SearchBarProps>((props, inputRef) => {
  const {
    debugName,
    onSearchChange,
    onTypingChange,
    onClear,
    placeholder,
    value,
    onValueChange,
    debounce = null,
    clearable = true,
    clearIcon = 'x' as IconPlaceholder,
    searchIcon = 'search' as IconPlaceholder,
    clearIconId,
    ...rest
  } = {
    ...props,
  }
  const innerInputRef = useRef<InputRef>(null)
  const setSearchTimeout = React.useRef<NodeJS.Timeout | null>(null)

  const handleChangeSearch = (value: string) => {
    onValueChange(value)

    if (TypeGuards.isNil(debounce)) {
      onSearchChange?.(value)
    } else {
      if (!TypeGuards.isNil(setSearchTimeout.current)) {
        clearTimeout(setSearchTimeout.current)
      }
      setSearchTimeout.current = setTimeout(() => {
        onSearchChange(value)
        onTypingChange?.(false)
      }, debounce ?? 0)
    }
  }

  const handleClear = () => {
    onValueChange('')
    onSearchChange?.('')
    if (TypeGuards.isFunction(onClear)) onClear?.()
  }

  const showClearIcon = !!value?.trim() && clearable

  useImperativeHandle(inputRef, () => {
    return {
      ...innerInputRef.current,
      ...innerInputRef.current?.getInputRef(),
    }
  }, [innerInputRef])

  return (
    <TextInput
      ref={innerInputRef as any}
      value={value}
      placeholder={placeholder}
      onChangeText={(value) => {
        onTypingChange?.(true)
        handleChangeSearch(value)
      }}
      debugName={`Search ${debugName}`}
      rightIcon={showClearIcon && {
        debugName: `${debugName}:RightIcon`,
        name: clearIcon,
        onPress: handleClear,
        id: clearIconId,
      }}
      leftIcon={{
        debugName: `${debugName}:LeftIcon`,
        name: searchIcon,
      }}
      {...rest}
    />
  )
},
) as unknown as (props: SearchBarProps) => ReactElement

