import { useBreakpointMatch, variantProvider } from '@/app'
import { View, GetAppButton, EmblaCarousel, Icon } from '@/components'
import { useCallback } from 'react'

type SmallSliderProps = {
  slide: number
  pages: any[]
  setSlide: (to: number) => void
}

export const SmallSlider = (props: SmallSliderProps) => {
  const {
    pages,
  } = props

  const slidesToShow = useBreakpointMatch({
    tabletSmall: 1.16,
    tablet: 1,
    laptop: 1,
    desktop: 1,
  })

  const renderItem = useCallback((item) => {

    const {
      children,
    } = item

    return (
      <View
        variants={['paddingHorizontal:1', 'fullWidth']}
      >
        <View
          variants={[
            'border-radius:medium',
            'backgroundColor:primary3',
            'fullWidth',
            'relative',
            'justifyCenter',
          ]}
          style={styles.item}
        >
          <Icon
            debugName='Feature Icon'
            name='thunder'
            variants={['color:primary7', 'absolute', 'fullHeight', 'fullWidth', 'top', 'left']}
            style={styles.itemIcon}
          />
          {children}
        </View>
      </View>
    )
  }, [])

  return (
    <View
      variants={['row', 'fullWidth']}
    >
      <View
        variants={['column', 'fullWidth']}
      >
        <EmblaCarousel
          items={pages}
          renderItem={({ item }) => renderItem(item)}
          options={{
            align: 'end',
            slidesToShow,
            dots: true,
          }}
        />
        <View variants={['fullWidth', 'paddingHorizontal:2']}>
          <GetAppButton
            text={'Download Skamper'}
            variants={['large', 'downloadSectionAppButton']}
            icon={null}
          />
        </View>
      </View>
    </View>
  )
}

const SECTION_HEIGHT = 400
const ITEM_ICON_POSITION = 40
const ITEM_IMAGE_WIDTH = 180

const styles = variantProvider.createComponentStyle(theme => ({
  innerWrapper: {
    borderRadius: theme.borderRadius.large,
    overflow: 'hidden',
    ...theme.presets.relative,
  },
  item: {
    height: SECTION_HEIGHT,
    overflow: 'hidden',
  },
  itemInnerWrapper: {
    zIndex: 1,
  },
  itemImage: {
    maxWidth: ITEM_IMAGE_WIDTH,
    ...theme.presets.fullWidth,
  },
  itemIcon: {
    top: -ITEM_ICON_POSITION,
    left: 0,
    bottom: 0,
    height: `calc(100% + ${ITEM_ICON_POSITION * 2}px)`,
    transform: 'rotate(19.61deg)',
  },
}), true)

