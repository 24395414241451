import { variantProvider } from '@/app'
import { AreaGrid } from '..'
import { Section } from '../Section'
import { Area } from '@/types'
import { PropsOf } from '@codeleap/common'
import { List, Grid } from '@/components'

export type GridAreaSectionProps = Partial<PropsOf<typeof Grid>> & {
  areas?: Area[]
  sectionProps?: PropsOf<typeof Section>
}

export const GridAreaSection = (props: GridAreaSectionProps) => {

  const { areas, sectionProps, ...rest } = props

  return (
    <Section
      title={"Britain's Best Runs by Area"}
      variants={['column']}
      {...sectionProps}
    >
      <AreaGrid
        areas={areas}
        {...rest}
      />
    </Section>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    cardWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: theme.spacing.value(4),
      [theme.media.down('mobile')]: {
        gridTemplateColumns: '1fr',
      },
    },
  }),
  true,
)
