import { Settings } from '@/app'
import { AnyFunction } from '@codeleap/common'

// NOTE This is the file were you put functions you don't know where else to put
const debounce = (func, delay = 1) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

function getPlatformLink() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/(Macintosh|iPhone|iPad|iPod)/i.test(userAgent)) {
    return `${Settings.Stores.Apple.url}`
  } else {
    return `${Settings.Stores.Android.url}`
  }
}

function convertParamsToRange(params: Record<string, string>, key: string): [number, number] | null {
  const min = params?.[`min_${key}`]
  const max = params?.[`max_${key}`]

  const results = [null, null] as [number, number]
  if (!min && !max) return null

  const parsedMax = parseInt(max)
  const parsedMin = parseInt(min)

  if (!!min) { results[0] = parsedMin }
  if (!!max) { results[1] = parsedMax }

  return results
}

function convertRangeToParams(range: [number, number], key: string) {
  const [min, max] = range || []
  const params = {}

  if (min !== undefined) { params[`min_${key}`] = min }
  if (max !== undefined) { params[`max_${key}`] = max }

  return params
}

const scrollToTop = (element, duration) => {
  if (!element) return
  const targetPosition = 0
  const startPosition = element.scrollTop
  const distance = targetPosition - startPosition
  const startTime = performance.now()

  const easeInOutQuad = (time, start, change, duration) => {
    time /= duration / 2
    if (time < 1) return change / 2 * time * time + start
    time--
    return -change / 2 * (time * (time - 2) - 1) + start
  }

  const animation = (currentTime) => {
    const elapsedTime = currentTime - startTime
    element.scrollTop = easeInOutQuad(elapsedTime, startPosition, distance, duration)
    if (elapsedTime < duration) {
      requestAnimationFrame(animation)
    }
  }

  requestAnimationFrame(animation)
}

function wait(ms: number) {
  return new Promise((resolve) => {
    // Simulating asynchronous operation
    setTimeout(() => {
      resolve('Operation completed')
    }, ms)
  })
}

const onScrollToWindowBottom = (onEndReached: AnyFunction) => {
  const totalPageHeight = document.documentElement.scrollHeight
  const viewportHeight = window.innerHeight
  const currentScrollPosition = window.scrollY
  if (currentScrollPosition + viewportHeight >= totalPageHeight) {
    onEndReached()
  }
}

const onScrollToComponentBottom = (componentRef: HTMLElement, onEndReached: AnyFunction) => {
  const componentHeight = componentRef?.clientHeight
  const componentOffsetTop = componentRef?.offsetTop
  const viewportHeight = window.innerHeight
  const currentScrollPosition = window.scrollY

  if (currentScrollPosition + viewportHeight >= componentOffsetTop + componentHeight) {
    onEndReached()
  }
}

const getImageAlt = (title: string, imageNumber = 1) => `${title} - Photo ${imageNumber}`

function removeLeadingSlash(str) {
  if (str.startsWith('/')) {
    return str.substring(1)
  }
  return str
}

function copyToClipboard(textToCopy: string) {
  return navigator.clipboard.writeText(textToCopy)
}

function formatToDecimal(num: number, decimalPlaces = 1) {
  if (num % 1 !== 0) {
    return num.toFixed(decimalPlaces)
  }
  return String(num)
}

export {
  formatToDecimal,
  copyToClipboard,
  removeLeadingSlash,
  getImageAlt,
  debounce,
  wait,
  getPlatformLink,
  scrollToTop,
  convertParamsToRange,
  convertRangeToParams,
  onScrollToWindowBottom,
  onScrollToComponentBottom,
}
