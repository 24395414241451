import { createTheme, validateTheme, VariantProvider } from '@codeleap/common'
import { textStyles } from './textStyles'
import { Icons } from './assets/icons'
import { logger } from './logger'
import { CSSObject } from '@emotion/react'
import { effects } from './effects'

const themeSpacing = 8

const light = {
  'primary1': '#F0F3FF',
  'primary2': '#CCD7FF',
  'primary3': '#262AC5',
  'primary4': '#393CA2',
  'primary5': '#323486',
  'primary6': '#E3E9FF',
  'primary7': '#4045CD',
  'secondary': '#E3E9FF',
  'secondary1': '##F0F3FF99',
  'secondary2': '##F0F3FF99',
  'secondary3': '##F0F3FF99',
  'secondary4': '##F0F3FF99',
  'secondary5': '##F0F3FF99',
  'terciary': '#F0F3FF',
  'neutral1': '#FFFFFF',
  'neutral2': '#F2F2F2',
  'neutral3': '#E5E5E5',
  'neutral4': '#D9D9D9',
  'neutral5': '#CCCCCC',
  'neutral6': '#B3B3B3',
  'neutral7': '#999999',
  'neutral8': '#666666',
  'neutral9': '#333333',
  'neutral10': '#171717',
  'positive1': '#DBF3E8',
  'positive2': '#42C586',
  'warning1': '#F5F0D1',
  'warning2': '#E4C000',
  'alert1': '#E4C000',
  'alert2': '#F33F3F',
  'destructive1': '#F3DBDB',
  'destructive2': '#F30A42',
  'background': '#FFFFFF',
  'card': '#FFFFFF',
  'separator': '#E5E5E5',
  'border': '#CCCCCC',
  'overlay': '#000000',
  'headlines': '#000',
  'body': '#666666',
  'caption': '#999999',
  'azure': '#007AFF',
  ripple: '#0002',
  transparent: '#FFF0',
  'lightWhite': '#FFFFFFCC',
  'orange1': '#FF922D',
}

const dark = {
  'primary1': '#F0F3FF99',
  'primary2': '#F0F3FF99',
  'primary3': '#262AC5',
  'primary4': '#262AC5',
  'primary5': '#323486',
  'primary6': '#E3E9FF',
  'primary7': '#4045CD',
  'secondary': '#E3E9FF',
  'secondary1': '##F0F3FF99',
  'secondary2': '##F0F3FF99',
  'secondary3': '##F0F3FF99',
  'secondary4': '##F0F3FF99',
  'secondary5': '##F0F3FF99',
  'terciary': '#F0F3FF',
  'neutral1': '#FFFFFF',
  'neutral2': '#F2F2F2',
  'neutral3': '#E5E5E5',
  'neutral4': '#D9D9D9',
  'neutral5': '#CCCCCC',
  'neutral6': '#B3B3B3',
  'neutral7': '#999999',
  'neutral8': '#666666',
  'neutral9': '#333333',
  'neutral10': '#000000',
  'positive1': '#4CB7D5',
  'positive2': '#F5F0D1',
  'warning1': '#F3DBDB',
  'warning2': '#4CB7D5',
  'alert1': '#E4C000',
  'alert2': '#F33F3F',
  'destructive1': '#F3DBDB',
  'destructive2': '#F33F3F',
  'background': '#FFFFFF',
  'card': '#4CB7D5',
  'separator': '#E5E5E5',
  'border': '#CCCCCC',
  'overlay': '#000000',
  'headlines': '#000',
  'body': '#666666',
  'caption': '#999999',
  'azure': '#007AFF',
  ripple: '#0002',
  transparent: '#FFF0',
  'lightWhite': '#FFFFFFCC',
  'orange1': '#FF922D',
}

export const themeBreakpoints = {
  zero: 0,
  mobileSmall: 350,
  mobile: 600,
  mobileLarge: 700,
  tabletSmall: 900,
  tablet: 1050,
  laptop: 1200,
  desktop: 1400,
  desktopLarge: 1800,
  desktopHuge: 2559,
}

export type ThemeBreakpoint = keyof typeof themeBreakpoints

const themeObj = validateTheme({
  colors: { light, dark },
  initialTheme: 'light',
  spacing: themeSpacing,
  borderRadius: {
    none: 0,
    tiny: 4,
    small: 8,
    smallish: 16,
    medium: 24,
    mediumish: 40,
    large: 32,
    rounded: 999999,
  },
  typography: {
    base: {
      fontFamily: 'DM Sans',
      styles: textStyles,
    },
    quotes: {
      fontFamily: 'DM Sans',
      styles: textStyles,
    },
  },
  icons: Icons,
  presets: {
    debugger: function (color: 'blue' | 'red' | 'yellow' | 'green' | 'purple' = 'red', background = false) {
      const hex = color === 'purple' ? '#9400D3' : color

      return {
        borderWidth: 1,
        borderColor: hex,
        color: hex,
        ...(background ? { backgroundColor: hex } : {}),
      }
    },
    mapCenter: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  effects,
  breakpoints: themeBreakpoints,
  values: {
    height: 10,
    width: 10,
    pixel: 1,
    innerSpacing: { X: 2, Y: 2, value: 16 },
    outerSpacing: { X: 2, Y: 2, value: 16 },
    gap: 2,
    smallGap: 1,
    itemHeight: {
      default: 45,
      small: 32,
      tinyish: 24,
      tiny: 20,
    },
    iconSize: {
      1: 16,
      2: 20,
      3: 24,
      4: 32,
      5: 48,
      6: 64,
    },
    maxContentWidth: 1280, // dont change this otherwise the useMaxContentWidth hook from codeleap/web will stop working
    headerHeight: 60,
    borderWidth: {
      small: 1,
      medium: 2,
    },
  },
  zIndexes: {
    header: 150,
    drawer: 1000,
    focusButton: 1,
    placesAutocomplete: 100,
    activityIndicator: 99,
    bannerSection: 2,
  },
  safeHorizontalPaddings: () => {
    return {
      desktopHuge: 0,
      desktopLarge: 18 * themeSpacing,
      desktop: 16 * themeSpacing,
      laptop: 12 * themeSpacing,
      tablet: 8 * themeSpacing,
      tabletSmall: 4 * themeSpacing,
      mobile: 2 * themeSpacing,
    }
  },
})

const appTheme = createTheme(themeObj, {
  screenSize: () => [0, 0],
})

export type TCSS = CSSObject

const styleGetter = (
  style: TCSS,
) => {

  return style
}

type StyleGetter = typeof styleGetter

export const variantProvider = new VariantProvider<
  StyleGetter,
  typeof themeObj
>(appTheme, styleGetter, logger)

export const Theme = variantProvider.theme

export type AppThemeModes = keyof typeof themeObj.colors

export type Breakpoint = keyof typeof themeObj.breakpoints
