import { React, Settings, AppImages, variantProvider } from '@/app'
import { View, CenterWrapper, Footer, Header, Image, ActivityIndicator } from '@/components'
import SEO, { SEOProps } from './SEO'
import { ComponentVariants, PropsOf, StylesOf, onUpdate, useDefaultComponentStyle, useNestedStylesByKey, useState } from '@codeleap/common'
import { useIsMobile, useUpdateLocale, useUpdateLocation } from '@/utils'
import { PageComposition, PageStyles } from '../app/stylesheets/Page'
import { ReactElement } from 'react'

const RESPONSIVITY_LOADING_TIME = 1000

export type PageProps = {
  showHeader?: boolean
  showFooter?: boolean
  showBackgroundImage?: boolean
  backgroundImageSource?: string
  backgroundImageProps?: Partial<PropsOf<typeof Image>>
  pageTitle?: string
  centerContent?: boolean
  SEOProps?: Partial<SEOProps>
  className?: string
  contentProps?: Partial<PropsOf<typeof CenterWrapper>>
  styles?: StylesOf<PageComposition>
  style?: React.CSSProperties
  children: React.ReactNode
  responsiveWrapper?: boolean
  HeaderComponent?: ReactElement
  seo?: boolean
} & ComponentVariants<typeof PageStyles>

const defaultProps: Partial<PageProps> = {
  showHeader: true,
  showFooter: true,
  centerContent: true,
  showBackgroundImage: false,
  backgroundImageSource: AppImages.LogoImage,
  SEOProps: {},
  responsiveWrapper: false,
  seo: true,
}

export const Page = (props: PageProps) => {
  const allProps = {
    ...Page.defaultProps,
    ...props,
  }

  const {
    showHeader,
    showFooter,
    pageTitle,
    centerContent,
    showBackgroundImage,
    backgroundImageSource,
    backgroundImageProps,
    SEOProps,
    className,
    children,
    variants,
    responsiveVariants,
    styles,
    contentProps,
    style,
    responsiveWrapper,
    HeaderComponent,
    seo,
    ...rest
  } = allProps

  const variantStyles = useDefaultComponentStyle<'u:Page', typeof PageStyles>('u:Page', {
    responsiveVariants,
    rootElement: 'wrapper',
    styles,
    variants,
  })

  const contentStyles = useNestedStylesByKey('content', variantStyles)
  const footerStyles = useNestedStylesByKey('footer', variantStyles)
  const headerStyles = useNestedStylesByKey('header', variantStyles)

  useUpdateLocale()
  useUpdateLocation()

  const isMobile = useIsMobile()
  const [loadingResponsivity, setLoadingResponsivity] = useState(false)
  const [firstRender, setFirstRender] = useState(true)

  const appendNameToPageTitle = SEOProps?.appendNameToPageTitle ?? false
  const appendedTitle = appendNameToPageTitle ? ` | ${Settings.AppName}` : ''
  const SEOTitle = pageTitle && `${pageTitle}${appendedTitle}`

  const innerWrapperStyles = React.useMemo(() => ([
    variantStyles.innerWrapper,
    variantStyles['innerWrapper:backgroundImage'],
  ]), [])

  const InnerWrapper = centerContent ? CenterWrapper : React.Fragment

  onUpdate(() => {
    if (responsiveWrapper && !firstRender) {
      setLoadingResponsivity(true)
      setTimeout(() => {
        setLoadingResponsivity(false)
      }, RESPONSIVITY_LOADING_TIME)
    }
    setFirstRender(false)
  }, [isMobile])

  if (responsiveWrapper && loadingResponsivity) {
    return (
      <View
        variants={['center']}
        css={[variantStyles.wrapper]}
      >
        <ActivityIndicator
          debugName='Responsivity Loader'
        />
      </View>
    )
  }

  return (
    <View css={[variantStyles.wrapper, style]} className={className} {...rest}>
      {!!seo && <SEO title={SEOTitle} {...SEOProps} />}

      {showBackgroundImage ? (
        <Image
          source={backgroundImageSource}
          style={variantStyles.backgroundImage}
          {...backgroundImageProps}
        />
      ) : null}

      <View css={[innerWrapperStyles, innerStyles.innerWrapper]}>
        {showHeader ? <Header styles={headerStyles} /> : null}
        {HeaderComponent}
        <InnerWrapper
          {...(centerContent ? { styles: contentStyles } : {})}
          {...contentProps}
        >
          {children}
        </InnerWrapper>
        {showFooter ? <Footer styles={footerStyles} /> : null}
      </View>
    </View>
  )
}

Page.defaultProps = defaultProps

const innerStyles = variantProvider.createComponentStyle(() => ({
  innerWrapper: {
    overflowX: 'hidden',
  },
}), true)
