import { MapUtils } from '@/utils'
import { toShortCoords } from '@/utils'
import GoogleMap from 'google-map-react'
import { MapDefaults, Settings } from '@/app'
import { MapStyles } from '@/app/stylesheets'
import { MapRefs, ShortCoords } from '@/types'
import { MapComposition } from '@/app/stylesheets/Map'
import { ActionIcon, ActivityIndicator, View } from '@/components'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { PropsOf, StylesOf, onMount, useDefaultComponentStyle, useState } from '@codeleap/common'
import { MapFullScreen } from './MapFullscreen'
import { useAppSelector } from '@/redux'

export type MapComponentProps = PropsOf<typeof View> & PropsOf<typeof GoogleMap> & {
  isLoading?: boolean
  mapButtonProps?: Omit<PropsOf<typeof ActionIcon>, 'debugName'>
  styles?: StylesOf<MapComposition>
  wrapperProps?: PropsOf<typeof View>
  expandable?: boolean
  userLocation?: ShortCoords
  mapRef?: MapRefs['mapRef']
  mapsRef?: MapRefs['mapsRef']
  onExpand?: () => void
}

const _MapComponent = ({
  ref,
  css,
  style,
  styles,
  mapRef,
  mapsRef,
  variants,
  id = 'map',
  expandable = false,
  userLocation,
  wrapperProps,
  zoom = MapDefaults.zoom.default,
  mapButtonProps,
  isLoading = false,
  onGoogleApiLoaded,
  onExpand,
  responsiveVariants,
  ...rest
}: MapComponentProps) => {
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const fullscreen = useAppSelector(store => store.MapRedux.fullscreen)

  const expandableProps: PropsOf<typeof ActionIcon> = {
    debugName: 'Map:ExpandButton',
    onPress: () => {
      MapUtils.toggleFullscreen()
      onExpand?.()
    },
    icon: 'maximize',
  }

  const showMapButton = expandable || !!mapButtonProps

  const variantStyles = useDefaultComponentStyle<
    'u:Map',
    typeof MapStyles
  >('u:Map', {
    variants,
    styles,
    responsiveVariants,
  })

  const handleApiLoaded = (props) => {
    if (mapRef) { mapRef.current = props.map }
    if (mapsRef) { mapsRef.current = props.maps }
    MapUtils.setThunderforestMapType(props?.map)

    onGoogleApiLoaded?.(props)
    setIsMapLoaded(true)
  }

  MapUtils.useDrawNavigationMarker({
    isMapLoaded,
    enabled: true,
    map: mapRef?.current,
    maps: mapsRef?.current,
    position: userLocation,
  })

  const mapProps = {
    onGoogleApiLoaded: handleApiLoaded,
    yesIWantToUseGoogleMapApiInternals: true,
    options: { mapId: Settings.ApiCredentials.GoogleMaps.MapId, disableDefaultUI: true },
    bootstrapURLKeys: { key: Settings.ApiCredentials.GoogleMaps.ApiKey, id },
    zoom: zoom,
    ...rest,
  }

  return (
    <View variants={variants} style={style} css={variantStyles.wrapper} ref={ref} id={'google-maps'} {...wrapperProps}>
      {isLoading ?
        (
          <View css={variantStyles.loadingWrapper} >
            <ActivityIndicator debugName='Map:Loading' variants={['marginBottom:2']} />
          </View>
        )
        :
        <GoogleMap {...mapProps} />
      }
      {!!showMapButton ? (
        <ActionIcon
          debugName='Map:Button'
          css={variantStyles.mapButtonWrapper}
          variants={['elevated', 'size:5', 'primary3', 'border-radius:smallish', 'iconSize:3', 'marginRight:4', 'marginBottom:4'] as any}
          {...(expandable ? expandableProps : {})}
          {...mapButtonProps} />
      ) : null}

      <MapFullScreen visible={fullscreen} toggle={MapUtils.toggleFullscreen} {...mapProps} />
    </View>
  )
}

// This wrapper is needed because everytime an usePlacesService needs to be called before map rendering
export const MapComponent = (props: MapComponentProps) => {
  const [isReady, setIsReady] = useState(false)

  usePlacesService({
    apiKey: Settings.ApiCredentials.GoogleMaps.ApiKey,
    options: {
      input: '',
      types: ['locality'],
      origin: toShortCoords(MapDefaults.center),
    },
  })

  onMount(() => {
    setTimeout(() => setIsReady(true))
  })

  if (!isReady) return null
  return <_MapComponent id='map' {...props}/>
}
