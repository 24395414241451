import { AppImages, useBreakpointMatch, variantProvider } from '@/app'
import {
  View,
  Text,
  Icon,
  CenterWrapper,
  Image,
  GetAppButton,
  EmblaCarousel,
} from '@/components'
import { getImageAlt, Mocks, useIsMobile, useMediaQueryDown } from '@/utils'
import { useCallback } from 'react'

const RATE = 5
const stars = Array(RATE).fill(0)

const Rating = ({ source, index }) => {

  let sourceText = ''
  let sourceImage = ''
  switch (source) {
    case 'apple-store':
      sourceText = 'From the Apple Store'
      sourceImage = AppImages.AppleStore
      break
    case 'play-store':
      sourceText = 'From the Play Store'
      sourceImage = AppImages.PlayStore
      break
    default:
      source = 'From the Apple Store'
      sourceImage = AppImages.AppleStore
  }

  return (
    <View variants={['fullWidth', 'center', 'column', 'gap:1']}>
      <View variants={['fullWidth', 'gap:1', 'center']}>
        {stars.map((_, index) => (
          <Icon
            key={`Star: ${index}`}
            debugName='Rating Star'
            style={styles.ratingIcon}
            variants={['color:warning2']}
            name='star'
          />
        ))}
      </View>

      <View variants={['gap:0.5']}>
        <Image
          alt={getImageAlt(`Testemonial ${sourceText}`, index + 1)}
          source={sourceImage}
          style={styles.storeImage}
          objectFit='cover'
        />
        <Text
          text={sourceText}
          variants={['p3', 'color:neutral9']}
        />
      </View>
    </View>
  )
}

export const Testimonials = () => {

  const isMobile = useIsMobile()
  const isTabletSmall = useMediaQueryDown('tabletSmall')

  const slidesToShow = useBreakpointMatch({
    mobileLarge: 1,
    tabletSmall: 2,
    desktopHuge: 3,
  })

  const Wrapper = isMobile ? View : CenterWrapper

  const renderItem = useCallback((item, index) => {

    return (
      <View
        variants={['paddingHorizontal:1', 'fullWidth']}
      >
        <View
          variants={['gap:3', 'padding:3', 'border-radius:medium', 'backgroundColor:neutral1', 'column', 'justifyStart', 'fullWidth']}
          responsiveVariants={{
            tabletSmall: ['center', 'paddingHorizontal:2'],
          }}
        >
          <Icon
            debugName={`Testimonial icon`}
            name='thunder'
            variants={['size:4', 'primary', 'color:primary4', 'marginHorizontal:auto']}
          />
          <Text
            text={item.testimonial}
            variants={['p3', 'color:neutral8', 'textLeft']}
          />
          <View
            variants={['gap:1', 'alignSelfStart', 'fullWidth', 'marginTop:auto']}
            responsiveVariants={{
              tabletSmall: ['center'],
            }}
          >
            <Rating source={item.source} index={index} />
          </View>

        </View>
      </View>
    )
  }, [])

  return (
    <Wrapper
      variants={['marginTop:4']}
      component='section'
    >
      <View
        variants={['column', 'gap:5', 'padding:10', 'fullWidth', 'relative']}
        responsiveVariants={{
          tablet: ['paddingVertical:10', 'paddingHorizontal:5'],
          tabletSmall: ['gap:0', 'paddingVertical:3', 'paddingHorizontal:2'],
        }}
        style={styles.innerWrapper}
      >
        <Icon
          debugName='Testimonial background icon'
          name='thunder'
          variants={['color:primary3', 'absolute', 'fullHeight', 'fullWidth', 'alignSelfCenter', 'marginHorizontal:auto']}
          style={styles.backgroundIcon}
        />
        <View
          variants={['column', 'gap:5', 'fullWidth']}
          responsiveVariants={{
            tabletSmall: ['gap:4'],
          }}
          style={styles.contentWrapper}
        >

          <View
            variants={['fullWidth', 'center']}
            responsiveVariants={{
              tabletSmall: ['marginBottom:0'],
            }}
          >
            <Text
              text='What runners say about Skamper'
              variants={['h1', 'color:primary4', 'textCenter']}
              responsiveVariants={{
                tabletSmall: ['h2', 'color:primary4'],
              }}
              component='h2'
            />
          </View>
          <EmblaCarousel
            items={Mocks.testimonials}
            renderItem={({ item, index }) => renderItem(item, index)}
            options={{
              slidesToShow,
              dots: isTabletSmall,
              arrows: !isTabletSmall,
            }}
            variants={['noUserSelect']}
          />

          <View
            variants={['fullWidth', 'paddingHorizontal:2', 'center']}
            responsiveVariants={{
              tabletSmall: ['marginTop:0'],
            }}
          >
            <GetAppButton
              text={'Download Skamper'}
              variants={['large', 'testimonalSectionAppButton']}
              icon={null}
            />
          </View>
        </View>
      </View>
    </Wrapper>
  )
}

const RATING_ICON_SIZE = 28
const STORE_IMAGE_SIZE = 22

const styles = variantProvider.createComponentStyle(theme => ({
  innerWrapper: {
    backgroundColor: theme.colors.primary1,
    borderRadius: theme.borderRadius.large,

    [theme.media.down('mobile')]: {
      borderRadius: theme.borderRadius.none,
    },
  },
  text: {
    zIndex: 1,
  },
  contentWrapper: {
    zIndex: 1,
  },
  backgroundIcon: {
    opacity: '8%',
    top: 0,
  },
  ratingIcon: {
    width: RATING_ICON_SIZE,
    height: RATING_ICON_SIZE,
  },
  storeImage: {
    width: STORE_IMAGE_SIZE,
    height: STORE_IMAGE_SIZE,
  },
  carouselDot: {
    backgroundColor: theme.colors.neutral5,
  },
}), true)

