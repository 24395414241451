import { variantProvider } from '@/app'
import { Pager, View, Icon, GetAppButton, StoreButton } from '@/components'
import { DotsWrapper } from './DotsWrapper'

const LargeItem = ({ item }) => {

  const {
    children,
  } = item

  return (
    <View
      variants={['fullHeight', 'fullWidth']}
      style={styles.largeItemWrapper}
    >
      {children}
    </View>
  )
}

type LargeSliderProps = {
  slide: number
  pages: any[]
  setSlide: (to: number) => void
}

export const LargeSlider = (props: LargeSliderProps) => {
  const {
    slide,
    pages,
    setSlide,
  } = props

  return (
    <View
      variants={['row', 'fullWidth']}
      style={styles.largeInnerWrapper}
    >
      <View
        variants={['column', 'gap:5', 'fullWidth']}
        style={styles.largeLeftContent}
      >
        <Pager
          debugName='pager:features'
          page={slide}
          disableSwipe
          onChange={() => null}
          css={[styles.pager]}
        >
          {pages.map((page, index) => {

            return (
              <LargeItem key={index} item={page} />
            )
          })}
        </Pager>

        <DotsWrapper
          page={slide}
          pages={pages}
          onPress={setSlide}
        />

        <Icon
          debugName='Feature Icon'
          name='thunder'
          variants={['color:primary7', 'absolute', 'fullHeight', 'fullWidth']}
          style={styles.innerWrapperIcon}
        />
      </View>

      <View
        variants={['padding:5', 'column', 'gap:3', 'backgroundColor:primary1', 'fullWidth', 'center']}
        responsiveVariants={{
          tabletSmall: ['padding:2'],
        }}
        style={styles.rightContent}
      >
        <GetAppButton
          text={'Get Skamper for free'}
          variants={['large', 'downloadSectionAppButton', 'fullWidth']}
          icon={null}
          style={styles.getAppButton}
        />

        <View
          variants={['gap:3']}
          responsiveVariants={{
            desktopLarge: ['column'],
          }}
        >
          <StoreButton store='apple' />
          <StoreButton store='play_store' />
        </View>
      </View>

    </View>
  )
}

const SECTION_HEIGHT = 400
const RIGHT_CONTENT_WIDTH = 320
const LARGE_LEFT_CONTENT_WIDTH = 672

const styles = variantProvider.createComponentStyle(theme => ({
  innerWrapper: {
    borderRadius: theme.borderRadius.large,
    overflow: 'hidden',
    ...theme.presets.relative,
  },
  largeInnerWrapper: {
    overflow: 'hidden',
    borderRadius: theme.borderRadius.large,
    height: SECTION_HEIGHT,
  },
  largeLeftContent: {
    backgroundColor: theme.colors.primary3,
    ...theme.presets.relative,
    maxWidth: LARGE_LEFT_CONTENT_WIDTH,
    width: '75%',
  },
  pager: {
    zIndex: 1,
  },
  largeItemWrapper: {
    height: SECTION_HEIGHT,
  },
  rightContent: {
    flex: 1,
    width: '30%',
  },
  rightContentText: {
    maxWidth: RIGHT_CONTENT_WIDTH,
  },
  rightContentBtn: {
    maxWidth: RIGHT_CONTENT_WIDTH,
  },
  innerWrapperIcon: {
    top: -SECTION_HEIGHT * 0.625,
    left: '3%', // -> 25 / 672
    bottom: 0,
    height: `calc(100% + ${SECTION_HEIGHT * 1.25}px)`,
    width: '81%',
    transform: 'rotate(25.22deg)',
  },
  getAppButton: {
    minHeight: theme.values.itemHeight.default,
    height: 'auto',
  },
}), true)
