exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-explore-tsx": () => import("./../../../src/pages/explore.tsx" /* webpackChunkName: "component---src-pages-explore-tsx" */),
  "component---src-pages-pace-calculator-embed-tsx": () => import("./../../../src/pages/pace_calculator/embed.tsx" /* webpackChunkName: "component---src-pages-pace-calculator-embed-tsx" */),
  "component---src-pages-pace-calculator-index-tsx": () => import("./../../../src/pages/pace_calculator/index.tsx" /* webpackChunkName: "component---src-pages-pace-calculator-index-tsx" */),
  "component---src-pages-profile-edit-tsx": () => import("./../../../src/pages/profile/edit.tsx" /* webpackChunkName: "component---src-pages-profile-edit-tsx" */),
  "component---src-pages-run-[id]-tsx": () => import("./../../../src/pages/run/[id].tsx" /* webpackChunkName: "component---src-pages-run-[id]-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms_and_conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-area-template-tsx": () => import("./../../../src/templates/AreaTemplate.tsx" /* webpackChunkName: "component---src-templates-area-template-tsx" */),
  "component---src-templates-areas-template-tsx": () => import("./../../../src/templates/AreasTemplate.tsx" /* webpackChunkName: "component---src-templates-areas-template-tsx" */),
  "component---src-templates-home-template-tsx": () => import("./../../../src/templates/HomeTemplate.tsx" /* webpackChunkName: "component---src-templates-home-template-tsx" */),
  "component---src-templates-run-template-tsx": () => import("./../../../src/templates/RunTemplate.tsx" /* webpackChunkName: "component---src-templates-run-template-tsx" */)
}

