import { api } from '@/app'
import { Course, Rating } from '@/types'
import { PaginationResponse, QueryManager, UsePaginationTypes, onUpdate, useState } from '@codeleap/common'
import { queryClient } from './queryClient'
import { useFlatlistProps } from '@/utils'

const BASE_URL = 'crm/web-rating/'

export async function list(params?: UsePaginationTypes.PaginationParams) {
  const response = await api.get<PaginationResponse<Rating>>(BASE_URL, {
    params,
  })
  return response.data
}

export const ratingsManager = new QueryManager<Rating>({
  itemType: {} as Rating,
  name: 'rating',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filter) => {
    const response = await list({ limit, offset, ...filter })
    return response
  },
})

export type UseTagsProps = {
  course: Course['id']
  stars?: Rating['stars'][]
}

export const useRatings = (props?: UseTagsProps) => {
  const { stars: _filterStars, ...rest } = props

  const [reviews, setReviews] = useState(0)
  const filterStars = _filterStars?.join(',')

  const ratings = ratingsManager.use({
    filter: {
      ...rest,
      stars: filterStars,
    },
    listOptions: {
      queryOptions: {
        refetchOnWindowFocus: false,
      },
    },
  })

  const listProps = useFlatlistProps(ratings, {
    noMoreItemsText: null,
  })

  const isLoading = ratings?.list?.query?.isLoading || listProps?.isLoading
  const isFetched = ratings?.list?.query?.isFetched

  // @ts-ignore
  const stars = ratings?.list?.query?.data?.pages?.[0]?.stars

  onUpdate(() => {
    if (stars) {
      const reviewsQtd = Object.values(stars).reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0) as number
      if (reviewsQtd !== reviews) {
        setReviews(reviewsQtd)
      }
    }
  }, [stars])

  return {
    ratings,
    listProps,
    isLoading,
    isFetched,
    stars,
    reviews,
  }
}
