import { variantProvider } from '@/app'
import { Modal, View, Button, Text } from '@/components'
import { copyToClipboard } from '@/utils'
import { PropsOf, useState } from '@codeleap/common'
import { graphql, useStaticQuery } from 'gatsby'

export type PaceCalculatorEmbedModalProps = PropsOf<typeof Modal> & {

}

export const PaceCalculatorEmbedModal = ({ toggle, ...rest }: PaceCalculatorEmbedModalProps) => {
  const [copied, setCopied] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const siteUrl = data.site.siteMetadata.siteUrl
  const iframeString = `<iframe
    src="${siteUrl}/pace_calculator/embed"
    width="425"
    height="800"
    frameborder="0"
    allowfullscreen>
  </iframe>`

  const handleCopy = () => {
    copyToClipboard(iframeString).then(() => { setCopied(true) })
  }

  const handleToggle = () => {
    setCopied(false)
    toggle?.()
  }

  return (
    <Modal
      title='Share'
      variants={['centered', 'titleLeft']}
      toggle={handleToggle}
      css={styles.modalWrapper}
      {...rest}
    >
      <View variants={['column', 'alignCenter', 'marginTop:1']}>
        <Text
          css={styles.text}
          variants={['neutral-9', 'p3']}
          text={iframeString}
        />

        <Button
          onPress={handleCopy}
          debugName='PaceCalculatorEmbedModal:Copy Code' variants={['large']}
          css={styles.button}
          text={copied ? 'Copied!' : 'Copy Code'}
        />
      </View>

    </Modal>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  modalWrapper: {
    maxWidth: 480,
  },
  text: {
    ...theme.border.neutral6(theme.values.borderWidth.small),
    borderRadius: theme.borderRadius.small,
    padding: theme.spacing.value(1),
  },
  button: {
    ...theme.presets.fullWidth,
    marginTop: theme.spacing.value(3),
    maxWidth: 320,
  },
}), true)

