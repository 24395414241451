import { LocalStorage, LocalStorageKeys, MyLocation } from '@/app'
import { UserLocationUtils } from './UserLocation'
import { toShortCoords } from './spatial'
import { PlacePrediction } from '@/types'

const getPlaceRowIconName = (type: string) => {
  switch (type) {
    case 'My Location':
      return 'navigation-outline'
    case 'Recent':
      return 'recent'
    default:
      return 'gps-pin'
  }
}

const saveSearch = (item) => {
  if (item?.place_id === MY_LOCATION_ID) return

  // @ts-ignore
  const recentSearches = LocalStorage.getItem(
    LocalStorageKeys.RECENT_SEARCH_PLACES,
  ) as any[]
  const existingIndex = recentSearches?.findIndex(
    (recentItem) => recentItem.place_id === item.place_id,
  )

  if (existingIndex !== -1) {
    recentSearches?.splice(existingIndex, 1)
  }
  const updatedSearches = recentSearches ? [item, ...recentSearches] : [item]
  LocalStorage.setItem(
    LocalStorageKeys.RECENT_SEARCH_PLACES as any,
    updatedSearches,
  )
}

const placeLocation = (placeDetails: any) => {
  const lng = placeDetails?.geometry?.location?.lng()
  const lat = placeDetails?.geometry?.location?.lat()

  return {
    lng,
    lat,
  }
}

const MY_LOCATION_ID = 'my-location'

const userLocationToPlace = () => {
  const userLocation = UserLocationUtils.getCurrentLocation()
  if (!userLocation) return null

  return {
    structured_formatting: {
      main_text: MyLocation.name,
      secondary_text: MyLocation.description,
    },
    place_id: MY_LOCATION_ID,
    location: toShortCoords(userLocation),
    type: 'My Location',
  } as PlacePrediction
}

const getPlaceTitle = (place: PlacePrediction) => place?.structured_formatting?.main_text || ''

export const AutocompleteUtils = {
  getPlaceTitle,
  getPlaceRowIconName,
  saveSearch,
  userLocationToPlace,
  placeLocation,
}
