import { variantProvider } from '../theme'
import { assignTextStyle } from './Text'

export type CourseDistanceComposition = 'wrapper' | 'value' | 'unit'

const createCourseDistanceStyles =
  variantProvider.createVariantFactory<CourseDistanceComposition>()

export const CourseDistanceStyles = {
  default: createCourseDistanceStyles((theme) => {
    const size = 48
    const contentColor = theme.colors.primary3

    return {
      wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyItems: 'center',
        padding: theme.spacing.value(1),
        borderRadius: theme.spacing.value(1.8),
        backgroundColor: theme.colors.primary1,
        width: size,
        height: size,
      },
      value: {
        ...assignTextStyle('h5')(theme).text,
        fontWeight: 700,
        color: contentColor,
      },
      unit: {
        ...assignTextStyle('p4')(theme).text,
        fontWeight: 700,
        color: contentColor,
      },
    }
  }),
  large: createCourseDistanceStyles((theme) => {
    const size = 80
    const contentColor = theme.colors.primary3

    return {
      wrapper: {
        ...theme.presets.column,
        ...theme.presets.center,
        ...theme.spacing.padding(1),
        borderRadius: theme.spacing.value(1.8),
        backgroundColor: theme.colors.primary1,
        width: size,
        height: size,
      },
      value: {
        ...assignTextStyle('h2')(theme).text,
        fontWeight: 700,
        color: contentColor,
      },
      unit: {
        ...assignTextStyle('h3')(theme).text,
        fontWeight: 700,
        color: contentColor,
      },
    }
  }),
  largeish: createCourseDistanceStyles((theme) => {
    const size = 60
    const contentColor = theme.colors.primary3

    return {
      wrapper: {
        ...theme.presets.column,
        ...theme.presets.center,
        ...theme.spacing.padding(1),
        borderRadius: theme.borderRadius.smallish,
        backgroundColor: theme.colors.primary1,
        width: size,
        height: size,
        minWidth: size,
      },
      value: {
        ...assignTextStyle('h3')(theme).text,
        fontWeight: 700,
        color: contentColor,
      },
      unit: {
        ...assignTextStyle('p2')(theme).text,
        fontWeight: 700,
        color: contentColor,
      },
    }
  }),
  'top-left': createCourseDistanceStyles((theme) => {
    const contentColor = theme.colors.neutral10
    const borderRadius = theme.borderRadius.small

    return {
      wrapper: {
        position: 'absolute',
        width: 'unset',
        height: 38,
        padding: theme.spacing.value(0.8),
        backgroundColor: theme.colors.neutral1,
        opacity: 0.8,
        borderRadius: 0,
        borderTopLeftRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
        top: 0,
        left: 0,
      },
      value: {
        fontSize: 12,
        fontWeight: 700,
        color: contentColor,
        lineHeight: 1.2,
      },
      unit: {
        fontSize: 8,
        color: contentColor,
        lineHeight: 0.5,
      },
    }
  }),
}
