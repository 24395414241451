import { Button } from '@/components'
import { PropsOf } from '@codeleap/common'
import { Navigation } from '@/utils'

type GetAppButtonProps = Omit<PropsOf<typeof Button>, 'debugName'>

export const GetAppButton = (props: GetAppButtonProps) => {

  const buttonVariants = props?.hasOwnProperty('variants') ? props?.variants : []

  return (
    <Button
      text={`Get the app!`}
      debugName={`Get the app button`}
      icon='skamper_logo'
      onPress={Navigation.General.navigateToStore}
      {...props}
      variants={['downloadAppButton', 'centerLeftIcon', ...buttonVariants]}
    />

  )
}
