import { useState } from 'react'
import { MapDefaults, variantProvider } from '@/app'
import { Slider } from '@codeleap/web'
import { PropsOf, TypeGuards, onUpdate } from '@codeleap/common'
import { View, FilterButton, Text, Button } from '@/components'
import { convertParamsToRange, convertRangeToParams } from '@/utils'

export type CourseFiltersProps = {
  params: Record<string, string>
  onSeeResults?: () => void
  setParams: React.Dispatch<React.SetStateAction<Record<string, string>>>
  results?: number
  isLoading?: boolean
  onApply?: () => void
}

type MaxMinFilter = [number, number]

type CourseFiltersModalFilters = 'paved' | 'hilly' | 'elevation' | 'distance'

type CourseFilterOption = Pick<PropsOf<typeof Slider>, 'trackMarks' | 'defaultValue' | 'min' | 'max'> & {
  key: CourseFiltersModalFilters
  value: MaxMinFilter
  suffix?: string
  label: string
  unlimited?: boolean
}

const CourseFilter = ({
  filter,
  filterVisible,
  handleClear,
  setFilterVisible,
  handleFilterPress,
  handleChange,
  params,
  ...rest
}) => {
  const [value, setValue] = useState(filter.value)
  const [hasChanges, setHasChanges] = useState(false)
  const { key, defaultValue, min, max, suffix = '', label, unlimited = false } = filter
  const _value = value || defaultValue
  const visible = filterVisible === key
  const hasFilter = filter.value !== null
  let minValue = TypeGuards.isNil(_value[0]) ? min : _value[0]
  let maxValue = TypeGuards.isNil(_value[1]) ? max : _value[1]
  const minTrack = `${minValue}${suffix}`
  let maxTrack = `${maxValue}${suffix}`

  if (maxValue >= max && unlimited) {
    maxTrack = `${max}+ ${suffix}`
    maxValue = max
  }

  if (minValue >= max && unlimited) {
    maxTrack = `${minValue}+ ${suffix}`
    minValue = max
  }

  const toggle = () => setFilterVisible(null)

  const range = convertParamsToRange(params, key)

  const rangeToCompare = TypeGuards.isNull(range) ? defaultValue : range
  const hasValueChanged = !value?.every((v, index) => v === rangeToCompare?.[index])

  onUpdate(() => {
    if (!visible && hasChanges) {
      setValue(filter.value)
      setHasChanges(false)
    }
  }, [visible, filter.value, filter.defaultValue])

  const disableApply = !hasValueChanged || (TypeGuards.isNull(value) && TypeGuards.isNull(range))

  return (
    <FilterButton
      {...rest}
      id={`filter-button-${key}`}
      disableApply={disableApply}
      debugName='Course:FilterButton'
      onClear={() => {
        handleClear(key)
        setValue(defaultValue)
        setHasChanges(false)
        toggle()
      }}
      visible={visible}
      toggle={toggle}
      buttonProps={{
        icon: hasFilter ? 'badge' : null,
        text: label,
        debugName: `Explore:FilterButton:${key}`,
        onPress: () => handleFilterPress(key),
      }}
      onApply={() => {
        handleChange(key, value)
        setHasChanges(false)
        toggle()
      }}
      sliderProps={{
        ...filter,
        key: key,
        debugName: `Explore:Slider:${key}`,
        value: [minValue, maxValue],
        minStepsBetweenThumbs: 0,
        onValueChange: (newValue) => {
          setValue(newValue)
          setHasChanges(true)
        },
        trackMarks: {
          [min]: minTrack,
          [max]: maxTrack,
        },
      }}
    />)
}

export const CourseFilters = ({ params, onApply, onSeeResults, setParams, results, isLoading }: CourseFiltersProps) => {
  const [filterVisible, setFilterVisible] = useState(null)

  const filterOptions: CourseFilterOption[] = [{
    label: 'Paved',
    key: 'paved',
    value: convertParamsToRange(params, 'paved'),
    defaultValue: [0, 100],
    min: 0,
    max: 100,
    suffix: '%',
  },
  {
    label: 'Hilly',
    key: 'hilly',
    value: convertParamsToRange(params, 'hilly'),
    min: 0,
    max: 5,
    defaultValue: [0, 5],
  },
  {
    label: 'Elevation',
    key: 'elevation',
    value: convertParamsToRange(params, 'elevation'),
    min: 0,
    max: 100,
    defaultValue: [0, 100],
    suffix: 'm',
    unlimited: true,
  },
  {
    label: 'Course distance',
    key: 'distance',
    value: convertParamsToRange(params, 'distance'),
    min: 0,
    max: 50,
    defaultValue: [0, 50],
    suffix: 'km',
    unlimited: true,
  },
  ]

  const handleChange = (key: CourseFiltersModalFilters, value: MaxMinFilter) => {

    setParams(state => {
      const { unlimited, max } = filterOptions.find(filter => filter.key === key)
      const isMaxReached = value?.[1] >= max
      const isUnlimited = isMaxReached && unlimited

      const _value = isUnlimited ? [value[0], null] : value

      const result = convertRangeToParams(_value as MaxMinFilter, key)
      return { ...state, ...result }
    })

    onApply?.()
  }

  const handleFilterPress = (key: CourseFiltersModalFilters) => {
    setFilterVisible(key)
  }

  const handleClear = (key: CourseFiltersModalFilters) => {
    setParams(state => {
      state[`min_${key}`] = null
      state[`max_${key}`] = null
      return { ...state }
    })
  }

  return (
    <View css={styles.outerWrapper}>
      <View css={styles.wrapper}>
        {filterOptions.map((filter) => {
          return (
            <CourseFilter
              filter={filter}
              key={filter.key}
              onSeeResults={onSeeResults}
              filterVisible={filterVisible}
              setFilterVisible={setFilterVisible}
              handleClear={handleClear}
              handleFilterPress={handleFilterPress}
              handleChange={handleChange}
              params={params}
            />
          )
        })}
      </View>
      <View variants={['center']}>
        {isLoading ? (
          <Text text={'Loading...'} variants={['p3', 'color:neutral9', 'marginLeft:1']} />
        ) : (
          <Text text={`${results || 0} results`} variants={['p3', 'color:neutral9', 'marginLeft:1', 'noWrap']} />
        )}
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  outerWrapper: {
    ...theme.presets.relative,
    minWidth: '100%',
    overflowX: 'auto',
    padding: theme.spacing.value(2),
    zIndex: MapDefaults.zIndex.filters,
    display: 'flex',
    background: theme.colors.neutral1,
  },
  wrapper: {
    gap: theme.spacing.value(1),
    display: 'flex',
    background: theme.colors.neutral1,
    ...theme.presets.relative,
    [theme.media.down('mobile')]: {
      paddingRight: theme.spacing.value(7),
    },
  },
}), true)
