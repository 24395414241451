import { ArrayCoords, Coords, LongCoords, ShortCoords } from '@/types'
import * as Turf from '@turf/turf'

export const normalizeCoords = (coords: Coords): LongCoords => ({
  latitude: !!coords?.latitude ? coords?.latitude : coords?.lat,
  longitude: !!coords?.longitude ? coords?.longitude : coords?.lng,
})

export const toArrayCoords = (coords: Coords): ArrayCoords => {
  const { latitude, longitude } = normalizeCoords(coords)
  return [longitude, latitude]
}

export const toLoongCoords = ({
  lat,
  lng,
  ...rest
}: ShortCoords): LongCoords => ({
  latitude: lat,
  longitude: lng,
  ...rest,
})

export const toShortCoords = ({
  latitude,
  longitude,
}: LongCoords): ShortCoords => ({ lat: latitude, lng: longitude })

export const distanceTo = (from: Coords, to: Coords) => {
  return Turf.distance(toArrayCoords(from), toArrayCoords(to), {
    units: 'meters',
  })
}

export const SpatialUtils = {
  distanceTo,
  normalizeCoords,
  toLoongCoords,
  toShortCoords,
  toArrayCoords,
}
