import { ExploreMobileFilters } from '../Courses/ExploreMap/components'
import { BottomDrawerBase } from './Base'
import { View, Text, ActionIcon } from '@/components'

export type FiltersBottomDrawerProps = {
  open: boolean
  onDismiss: () => void
  params: Record<string, string>
  setParams: React.Dispatch<React.SetStateAction<Record<string, string>>>
  onApply?: () => void
}

export const FiltersBottomDrawer = (props: FiltersBottomDrawerProps) => {

  const { open, onDismiss, ...rest } = props

  return (
    <BottomDrawerBase
      open={open}
      onDismiss={onDismiss}
      {...rest}
    >
      <View variants={['justifySpaceBetween', 'marginBottom:2']}>
        <Text variants={['bold']} text={'Filters'} />
        <ActionIcon
          icon={'close'}
          variants={['normalize', 'neutral9']}
          debugName='Close Filters Drawer'
          onPress={onDismiss}
        />
      </View>
      <ExploreMobileFilters
        onClear={onDismiss}
        onApply={onDismiss}
        isOpen={open}
        {...rest}
      />
    </BottomDrawerBase>
  )
}
