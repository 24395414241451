import { PlaceRowComposition, PlaceRowStyles } from '@/app/stylesheets'
import { View, Touchable, Icon, Text } from '@/components'
import { AutocompleteUtils, Units } from '@/utils'
import { ComponentVariants, PropsOf, StylesOf, getNestedStylesByKey, useDefaultComponentStyle } from '@codeleap/common'
import { PlacePrediction } from '@/types'
import { MyLocation } from '@/app'

type PlaceRowType = 'My Location' | 'Recent' | 'Autocomplete'

export type PlaceRowProps = {
  place?: PlacePrediction
  styles?: StylesOf<PlaceRowComposition>
  onPress?: (place: PlacePrediction, type: PlaceRowType) => void
  noDescription?: boolean
  type: PlaceRowType
} & Omit<PropsOf<typeof Touchable>, 'styles' | 'variants' | 'debugName'>
  & ComponentVariants<typeof PlaceRowStyles>

export const PlaceRow = ({
  variants,
  styles,
  responsiveVariants,
  place,
  noDescription = false,
  type = 'Autocomplete',
  onPress,
  ...rest }: PlaceRowProps) => {
  const variantStyles = useDefaultComponentStyle<
    'u:PlaceRow',
    typeof PlaceRowStyles
  >('u:PlaceRow', {
    variants,
    styles,
    responsiveVariants,
  })

  const getStyles = (key: string) => ({
    ...variantStyles[key],
    ...getNestedStylesByKey(key, variantStyles),
  })

  const isMyLocation = type === MyLocation.name

  const title = place?.structured_formatting?.main_text
  const iconName = AutocompleteUtils.getPlaceRowIconName(type)
  const description = place?.structured_formatting?.secondary_text
  const meters = place?.distance_meters
  const kmDistance = Units.convert({ value: meters, from: 'm', to: 'km', toFixed: 1 })
  const distance = place?.distance_meters ? `${kmDistance} km away` : 'Distance not available'

  return (
    <Touchable
      debugName={`PlaceRow:${place?.place_id}`}
      variants={['justifyStart']}
      onPress={() => {
        onPress(place, type)
      }}
      css={variantStyles.touchableWrapper}
      {...rest}
    >
      <Icon name={iconName} variants={['medium']} css={getStyles('icon')} debugName='AutoComplete place icon' />
      <View
        css={getStyles('contentWrapper')}
      >
        <Text
          text={title}
          css={getStyles('title')}
        />

        {(!noDescription && !isMyLocation) && (
          <View css={getStyles('infoWrapper')}>
            <Text text={distance} css={getStyles('distance')} />
            <Text text={'•'} css={getStyles('separator')} />
            <Text text={description} css={getStyles('description')} />
          </View>)
        }
      </View>
    </Touchable>
  )
}
