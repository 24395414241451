import { variantProvider } from '@/app'
import { View, Text, ProgressBar, Icon, RatingStars, ActivityIndicator, CourseRating, List, RatingFilter } from '@/components'
import { APIClient } from '@/services'
import { Course } from '@/types'
import { CourseUtils } from '@/utils'
import { PropsOf, TypeGuards, useCallback } from '@codeleap/common'
import { useSearchParams } from '@codeleap/web'
import React from 'react'

type CourseRatingsProps = {
  course: Course['id']
} & PropsOf<typeof View>

const grades = ['5', '4', '3', '2', '1']

const RatingOverview = ({ stars, reviews }) => {

  const score = CourseUtils.calculateRatingScore(stars, reviews)

  return (
    <View
      variants={['gap:5', 'row', 'flex', 'alignCenter']}
      responsiveVariants={{
        mobile: ['gap:2', 'column', 'flex', 'alignStart'],
      }}
    >
      <View variants={['gap:2', 'column', 'fullWidth']} style={styles.gradesWrpper}>
        {grades.map((grade, index) => {
          const progress = reviews === 0 ? reviews : (stars?.[grade] / reviews) * 100

          return (
            <View key={`ProgressBar: ${grade}${index}`} variants={['gap:1']}>
              <Text text={`${grade}`} variants={['h4', 'color:neutral8']} style={{ width: 13 }} />

              <Icon
                debugName='Rating Star'
                style={styles.ratingIcon}
                variants={['orange1', 'size:3']}
                name='star'
              />

              <ProgressBar progress={progress} variants={['rating']} />
            </View>
          )
        })}
      </View>

      <View variants={['gap:2', 'column']} style={styles.headlineWrapper}>
        <Text text={`${score?.toFixed(1)}`} component='h3' variants={['hx', 'color:neutral10']} style={styles.headlineGrade} />

        <View variants={['gap:1', 'column']}>
          <RatingStars stars={5} score={score} />

          <Text text={`${reviews} reviews`} variants={['p1']} />
        </View>
      </View>
    </View>
  )
}

export type RatingFilters = {
  stars: number[]
}

export const CourseRatings = (props: CourseRatingsProps) => {
  const { course, ...rest } = props

  const [params, setParams] = useSearchParams()

  const { ratings, stars, reviews, isLoading, listProps, isFetched } = APIClient.Rating.useRatings({
    course,
    stars: params?.stars?.split(',')?.map(star => Number(star)),
  })

  const noReviews = ratings?.items?.length === 0
  const showLoader = isLoading || (isFetched && !noReviews && reviews === 0) || TypeGuards.isUndefined(stars)

  const renderItem = useCallback(({ item, index }) => {
    const isFirst = index === 0
    return (
      <View
        variants={['paddingVertical:2']}
        responsiveVariants={{
          mobile: ['paddingVertical:3'],
        }}
        style={{
          ...styles.ratingWrapper,
          ...(isFirst ? styles.firstRatingWrapper : null),
        }}
        component='li'
      >
        <CourseRating rating={item} />
      </View>
    )
  }, [])

  if (showLoader) {
    return (
      <View {...rest}>
        <ActivityIndicator variants={['fullWidth']} debugName='Course Rating Loader' />
      </View>
    )
  }

  return (
    <View component='section' {...rest}>
      <View variants={['gap:3', 'column', 'fullWidth', 'alignSelfStart']}>
        <Text text={`Reviews (${reviews})`} variants={['h2', 'color:neutral9']} component='h2' />

        <View variants={['column', 'gap:2', 'flex']}>
          <RatingFilter
            params={params}
            setParams={setParams}
          />
          <RatingOverview stars={stars} reviews={reviews} />
        </View>

        <List
          {...listProps}
          debugName='RatingList'
          data={listProps?.data as unknown as string[]}
          renderItem={renderItem}
          rowItemsSpacing={0}
          placeholder={null}
          variants={['minHeight:0']}
          ListEmptyComponent={() => <></>}
          layoutWrapperProps={{
            component: 'ol'
          }}
        />
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  ratingIcon: {
    width: theme.values.iconSize[3],
    height: theme.values.iconSize[3],
  },
  gradesWrpper: {
    [theme.media.down('mobile')]: {
      order: 1,
    },
  },
  headlineWrapper: {
    [theme.media.down('mobile')]: {
      order: 0,
      ...theme.presets.row,
      ...theme.presets.alignCenter,
    },
  },
  headlineGrade: {
    lineHeight: `${theme.spacing.value(9)}px`,
  },
  ratingWrapper: {
    ...theme.border.neutral5({ 'directions': ['bottom'], 'width': 1 }),
  },
  firstRatingWrapper: {
    ...theme.border.neutral5({ 'directions': ['top'], 'width': 1 }),
  },
}), true)
