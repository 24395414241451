import React from 'react'
import { CenterWrapper, Logo, Link, Navigator, Drawer } from '@/components'
import { useDefaultComponentStyle, ComponentVariants, StylesOf, PropsOf } from '@codeleap/common'
import { DrawerMenu } from './Drawer'
import { NavContent } from './Content'
import { HeaderComposition, AppHeaderStyles, BREAKPOINT } from '../../app/stylesheets/Header'
import { useMediaQueryDown } from '@/utils'

export type HeaderProps =
  Omit<PropsOf<typeof CenterWrapper>, 'variants' | 'styles' | 'responsiveVariants'> &
  ComponentVariants<typeof AppHeaderStyles> &
  {
    styles?: StylesOf<HeaderComposition>
    drawerProps?: Partial<PropsOf<typeof Drawer>>
  }

const HeaderComponent = (props: HeaderProps) => {
  const {
    responsiveVariants = {},
    styles = {},
    variants = [],
    drawerProps = {},
    ...rest
  } = props

  const isMobile = useMediaQueryDown(BREAKPOINT)

  const variantStyles = useDefaultComponentStyle<'u:Header', typeof AppHeaderStyles>('u:Header', {
    responsiveVariants,
    styles,
    variants,
  })

  return (
    <Navigator.Container>
      <CenterWrapper
        styles={{
          wrapper: variantStyles.wrapper,
          innerWrapper: variantStyles.innerWrapper,
        }}
        component='header'
        {...rest}
      >
        <Link route='Home' css={variantStyles.logoWrapper}>
          <Logo debugName='header:logo' style={variantStyles.logo} />
        </Link>

        {isMobile ? (
          <DrawerMenu
            {...drawerProps}
            variantStyles={variantStyles}
            isMobile={isMobile}
          />
        ) : (
          <NavContent
            variantStyles={variantStyles}
            isMobile={isMobile}
          />
        )}
      </CenterWrapper>
    </Navigator.Container>
  )
}

export const Header = React.memo(HeaderComponent, () => true)
